import React from 'react';
import '../CSS/AdmissionProcedure.css';
import Navbar from '../components/Navbar';
import FloatingButton from '../components/Floatingbutton';
import JoinUs from '../components/JoinUs';
import Footer from '../components/Footer';

const AdmissionProcedure = () => {
    const steps = [
        "Obtain the prospectus by visiting the institute.",
        "Provide attested copies of your certificates along with originals, 4 passport-size pictures, and CNIC of the student and father/guardian.",
        "Fill in the attached form, sign, and deposit it with the admission branch.",
        "Receive the bank voucher and deposit the fee in the prescribed bank.",
        "Return the voucher to the admission branch.",
        "The admission branch will provide a registration number.",
        "Provisional admissions will be given.",
        "Confirmation will be done once your papers/certificates are verified.",
        "For distant/abroad students, a representative with an authority letter on stamped paper can visit the institute for admission. However, admission will be confirmed upon joining."
      ];
      

  return (

    <>
    <Navbar/>
    <FloatingButton/>
     <section className="section py-3">
        <main className="hero-content">
          <div className=" container hero internal">
            <div className="hero-section-content">
              <div className="hero-section-content-block internal bg-light-red">
                <div className="wrapper max-width-640-mobile-480">
                  <h1 className="margin-bottom-28">
                  Your Future Starts Here
                    {/* Our Leaders, Your Guides */}
                  </h1>
                  <p className="regular-xl max-width-420">
                  Effortlessly navigate our admission procedure and take the first step toward a brighter, more successful tomorrow.
                  </p>
                </div>
              </div>
              <div className="hero-section-image internal advanced-program"></div>
            </div>
          </div>
        </main>
      </section>
    <div className="container">
      <h3 className='my-5'>Admission Procedure</h3>
      <ol className="steps">
        {steps.map((step, index) => (
          <li key={index} className="step">
            <div><div className="step-number">{index + 1}</div></div>
            
            <div className="step-text">{step}</div>
          </li>
        ))}
      </ol>
    </div>

    <JoinUs/>
    <Footer/>
    </>
  );
};

export default AdmissionProcedure;
