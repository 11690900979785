import React from "react";
import Navbar from "../../components/Navbar";
import FloatingButton from "../../components/Floatingbutton";
import JoinUs from "../../components/JoinUs";
import Footer from "../../components/Footer";

function DaeCivil() {
  return (
    <>
      <Navbar />
      <FloatingButton />

      <section class="section">
        <main class="hero-content">
          <div class="w-layout-blockcontainer container hero internal w-container">
            <div class="hero-section-content">
              <div class="hero-section-content-block internal bg-light-red">
                <div class="wrapper max-width-640-mobile-480">
                  <h1 class=" margin-bottom-28">
                    Elevate Your Advanced Education
                  </h1>
                  <p class="regular-xl max-width-420">
                    Challenge yourself with rigorous coursework, expand your
                    knowledge, and prepare for success in college and beyond.
                  </p>
                </div>
              </div>
              <div class="hero-section-image internal advanced-program"></div>
            </div>
          </div>
        </main>
      </section>
      
      <div className="mt-5 container lh-lg">
        <header className="text-center fw-bold">
          <h2>Diploma of Associate Engineering (DAE) in Civil Technology</h2>
        </header>

        <section class="mt-5">
          <p>
            Civil Technology deals with the planning, designing, execution,
            operation, and maintenance of civil engineering projects including
            buildings, dams, railways, airports, and roads. The program is
            structured to provide a balanced mix of theoretical knowledge and
            practical skills through extensive laboratory work and project-based
            learning.
          </p>
        </section>

        <section class="mt-5">
          <h3>Laboratories</h3>
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Lab</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>Surveying Lab</strong>
                </td>
                <td>
                  Equipped with advanced surveying instruments such as
                  theodolites, total stations, and GPS to train students in
                  precise measurement and mapping techniques.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Well-equipped Drawing Hall</strong>
                </td>
                <td>
                  Provides students with the facilities to develop and refine
                  their technical drawing skills, crucial for effective
                  communication in engineering projects.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Public Health Lab</strong>
                </td>
                <td>
                  Focuses on the study of environmental engineering, water
                  supply, and sanitation systems to ensure public health and
                  safety.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Construction Lab</strong>
                </td>
                <td>
                  Offers practical training in construction materials and
                  techniques, allowing students to understand the properties and
                  applications of various building materials.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Hydraulics Lab</strong>
                </td>
                <td>
                  Contains equipment to study the behavior of fluids, essential
                  for designing and managing water resources and hydraulic
                  structures.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Computer Lab</strong>
                </td>
                <td>
                  Equipped with the latest software used in civil engineering
                  for design, analysis, and project management.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Physics Lab</strong>
                </td>
                <td>
                  Provides a foundation in the principles of physics, essential
                  for understanding the mechanics and dynamics involved in civil
                  engineering.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Chemistry Lab</strong>
                </td>
                <td>
                  Focuses on the chemical properties of materials, crucial for
                  selecting appropriate construction materials and ensuring
                  their durability.
                </td>
              </tr>
            </tbody>
          </table>
        </section>


        <section className="d-flex flex-md-nowrap flex-wrap justify-content-around my-5">
          <div className="col-md-4 col-12">
            <img className="img-fluid" src={require("../../Photos/Civil_lab_1.jpg")}></img>
          </div>
          <div className="col-md-4 col-12 mx-md-1 my-md-0 my-2">
            <img className="img-fluid" src={require("../../Photos/Civil_lab_2.jpg")}></img>
          </div>
          <div className="col-md-4 col-12">
            <img className="img-fluid" src={require("../../Photos/Civil_lab_3.jpg")}></img>
          </div>
        </section>


        <section class="scheme-of-study">
          <h2>Scheme of Study for Civil Technology</h2>
          <div class=" mt-5 " style={{ overflowX: "auto" }}>
            <h2 class="">FIRST YEAR</h2>
            <table class="table table-striped table-bordered table-bordered">
              <thead>
                <tr>
                  <th>SNO</th>
                  <th>1st Year</th>
                  <th>Code</th>
                  <th>Subject</th>
                  <th>T</th>
                  <th>P</th>
                  <th>C</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Gen</td>
                  <td>111</td>
                  <td>Islamyat & Pak Studies</td>
                  <td>1</td>
                  <td>0</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Eng</td>
                  <td>112</td>
                  <td>English</td>
                  <td>2</td>
                  <td>0</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Math</td>
                  <td>113</td>
                  <td>Applied Mathematics-1</td>
                  <td>3</td>
                  <td>0</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Phy</td>
                  <td>112</td>
                  <td>Applied Chemistry</td>
                  <td>1</td>
                  <td>3</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Chem</td>
                  <td>122</td>
                  <td>Applied Physics</td>
                  <td>1</td>
                  <td>3</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Civil</td>
                  <td>104</td>
                  <td>Basic Civil Engineering Surveying</td>
                  <td>2</td>
                  <td>6</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>Civil</td>
                  <td>113</td>
                  <td>Engineering Materials & Construction Techniques</td>
                  <td>2</td>
                  <td>3</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>Civil</td>
                  <td>143</td>
                  <td>Basic Civil Engineering Drawing</td>
                  <td>1</td>
                  <td>6</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>Civil</td>
                  <td>102</td>
                  <td>Workshop Practice</td>
                  <td>1</td>
                  <td>3</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>Civil</td>
                  <td>111</td>
                  <td>Computer Application</td>
                  <td>0</td>
                  <td>3</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td colspan="4">
                    <strong>Total</strong>
                  </td>
                  <td>
                    <strong>14</strong>
                  </td>
                  <td>
                    <strong>27</strong>
                  </td>
                  <td>
                    <strong>23</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="mt-5" style={{ overflowX: "auto" }}>
            <h2 class="">SECOND YEAR</h2>
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SNO</th>
                  <th>2nd Year</th>
                  <th>Code</th>
                  <th>Subject</th>
                  <th>T</th>
                  <th>P</th>
                  <th>C</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Gen</td>
                  <td>211</td>
                  <td>Islamyat & Pak Studies</td>
                  <td>1</td>
                  <td>0</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Math</td>
                  <td>233</td>
                  <td>Applied Mathematics-II</td>
                  <td>2</td>
                  <td>0</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Gen</td>
                  <td>221</td>
                  <td>Communication Skills & Report Writing</td>
                  <td>1</td>
                  <td>0</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Civil</td>
                  <td>203</td>
                  <td>Public Health Technology</td>
                  <td>2</td>
                  <td>3</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Civil</td>
                  <td>214</td>
                  <td>Advanced Civil Engineering Surveying</td>
                  <td>2</td>
                  <td>6</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Civil</td>
                  <td>223</td>
                  <td>Advanced Construction Techniques</td>
                  <td>2</td>
                  <td>3</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>Civil</td>
                  <td>232</td>
                  <td>Quantity Surveying</td>
                  <td>1</td>
                  <td>3</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>Civil</td>
                  <td>243</td>
                  <td>Civil Engineering Drawing & Auto CAD</td>
                  <td>1</td>
                  <td>6</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>Civil</td>
                  <td>263</td>
                  <td>Engineering Mechanics</td>
                  <td>2</td>
                  <td>3</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>Civil</td>
                  <td>271</td>
                  <td>Entrepreneurship</td>
                  <td>1</td>
                  <td>0</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td colspan="4">
                    <strong>Total</strong>
                  </td>
                  <td>
                    <strong>15</strong>
                  </td>
                  <td>
                    <strong>24</strong>
                  </td>
                  <td>
                    <strong>23</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class=" mt-5" style={{ overflowX: "auto" }}>
            <h2 class="">THIRD YEAR</h2>
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SNO</th>
                  <th>1st Year</th>
                  <th>Code</th>
                  <th>Subject</th>
                  <th>T</th>
                  <th>P</th>
                  <th>C</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Gen</td>
                  <td>311</td>
                  <td>Islamyat & Pak Studies</td>
                  <td>1</td>
                  <td>0</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Civil</td>
                  <td>303</td>
                  <td>Advanced Quantity Surveying</td>
                  <td>1</td>
                  <td>6</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Civil</td>
                  <td>314</td>
                  <td>Construction Project Planning & Management</td>
                  <td>2</td>
                  <td>6</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Civil</td>
                  <td>322</td>
                  <td>Environment Health & Safety</td>
                  <td>2</td>
                  <td>0</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Civil</td>
                  <td>334</td>
                  <td>Hydraulic & Irrigation Engineering</td>
                  <td>3</td>
                  <td>3</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Civil</td>
                  <td>343</td>
                  <td>Transportation Engineering</td>
                  <td>2</td>
                  <td>3</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>Civil</td>
                  <td>354</td>
                  <td>Concrete Technology & RCC Design</td>
                  <td>3</td>
                  <td>3</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>Civil</td>
                  <td>373</td>
                  <td>Soil Mechanics & Bridge Engineering</td>
                  <td>2</td>
                  <td>3</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td colspan="4">
                    <strong>Total</strong>
                  </td>
                  <td>
                    <strong>16</strong>
                  </td>
                  <td>
                    <strong>24</strong>
                  </td>
                  <td>
                    <strong>24</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        <section class="my-5">
          <h2>Career Opportunities</h2>
          <p>
            Graduates of the DAE Civil Technology program can pursue careers as
            site supervisors, civil engineering technicians, CAD technicians,
            surveyors, and construction managers in both public and private
            sectors. They can also further their education by pursuing a
            Bachelor's degree in Civil Engineering or related fields.
          </p>
        </section>
      </div>

      <JoinUs />
      <Footer />
    </>
  );
}

export default DaeCivil;
