import React from "react";
import Navbar from "../components/Navbar";
import FloatingButton from "../components/Floatingbutton";
import JoinUs from "../components/JoinUs";
import Footer from "../components/Footer";

function Library() {
  return (
    <>
      <Navbar />
      <FloatingButton />

      <section className="section py-3">
        <main className="hero-content">
          <div className=" container hero internal">
            <div className="hero-section-content">
              <div className="hero-section-content-block internal bg-light-red">
                <div className="wrapper max-width-640-mobile-480">
                  <h1 className="margin-bottom-28">
                    Unlocking Knowledge, Inspiring Futures
                    {/* Our Leaders, Your Guides */}
                  </h1>
                  <p className="regular-xl max-width-420">
                    At SIT, our library is more than shelves—it's a hub of
                    learning, offering expansive resources and expert guidance.
                    We empower students to explore, innovate, and excel in their
                    academic journey and beyond
                  </p>
                </div>
              </div>
              <div className="hero-section-image internal Library-background"></div>
            </div>
          </div>
        </main>
      </section>
      <section className="container mt-5 lh-lg">
        <h2 className="mb-3">Library</h2>
        <p>
          The library at our esteemed technical Institute stands as a beacon of
          knowledge, offering a myriad of books spanning various genres and
          disciplines. From timeless classics to contemporary research, it
          houses a comprehensive collection to meet diverse interests. Enter
          this sanctuary of learning, where each book serves as a portal to
          endless discoveries and enlightenment.
        </p>
      </section>

      <JoinUs />
      <Footer />
    </>
  );
}

export default Library;
