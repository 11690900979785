import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import JoinUs from "../components/JoinUs";
import FloatingButton from "../components/Floatingbutton";

function FeeStructure() {
  return (
    <>
      <Navbar />
      <FloatingButton />
      <section class="section">
        <main class="hero-content">
          <div class="w-layout-blockcontainer container hero internal w-container">
            <div class="hero-section-content">
              <div class="hero-section-content-block internal bg-light-red">
                <div class="wrapper max-width-640-mobile-480">
                  <h1 class=" margin-bottom-28">
                  Discover Fee Structures Easily
                  </h1>
                  <p class="regular-xl max-width-420">
                    Explore detailed fee structures for Skans Institute of
                    Technology, making your decision easy and informed.
                  </p>
                </div>
              </div>
              <div class="hero-section-image internal advanced-program"></div>
            </div>
          </div>
        </main>
      </section>

      <div class="container mt-4" style={{overflowX:"auto"}}>
        <h1 className="mb-3">Fee Structure</h1>
        <table class="table table-striped table-bordered text-center">
          <tbody>
            <tr className="table-active">
              <th>Programs</th>
              <th>Admission fee one time</th>
              <th>Tuition fee per month</th>
              <th>Security fee (one time Refundable)</th>
              <th>Student Card (one time)</th>
              <th>Sports fund annually</th>
              <th>Exam Charges Annually</th>
            </tr>
            <tr>
              <td>DAE Civil</td>
              <td>10,000</td>
              <td>6,000</td>
              <td>5,000</td>
              <td>200</td>
              <td>500</td>
              <td>2,000</td>
            </tr>
            <tr>
              <td>DAE Electrical</td>
              <td>10,000</td>
              <td>6,000</td>
              <td>5,000</td>
              <td>200</td>
              <td>500</td>
              <td>2,000</td>
            </tr>
            <tr>
              <td>DAE Mechanical</td>
              <td>10,000</td>
              <td>6,000</td>
              <td>5,000</td>
              <td>200</td>
              <td>500</td>
              <td>2,000</td>
            </tr>
            <tr>
              <td>DAE CIT</td>
              <td>10,000</td>
              <td>6,000</td>
              <td>5,000</td>
              <td>200</td>
              <td>500</td>
              <td>2,000</td>
            </tr>
          </tbody>
        </table>
      </div>
      <JoinUs />
      <Footer />
    </>
  );
}

export default FeeStructure;
