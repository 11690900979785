import React from "react";
import "../../CSS/Profiles.css";
import Navbar from "../../components/Navbar";
import FloatingButton from "../../components/Floatingbutton";
import Footer from "../../components/Footer";
import JoinUs from "../../components/JoinUs";
import { useParams } from "react-router-dom";
function Profiles({ teamMember }) {
    const { id } = useParams();
    const teamMembers = [
      {
        id: 1,
        photo: require('../../Photos/HOD_Mechanical.png'),
        name: "M. Naweed Hassan",
        position: "Principal SIT",
        detail: "M Naweed Hassan, a mechanical engineering graduate from UET Lahore in 1982, embarked on a distinguished career path. Beginning at the Heavy Rebuild Factory Taxila, he transitioned to the Corps of Electrical and Mechanical Engineering of the Pak Army, accumulating 25 years of service with diverse assignments and professional training both domestically and internationally. His impactful roles encompassed instructional duties at a military college for six years and leadership in the Tank Al Khalid Development project for nearly two decades, involving design, trials, and production, alongside international engagements. Following his retirement in 2010, he joined NUST as a faculty member in the Department of Mechanical Engineering for eleven years, playing a pivotal role in establishing and managing the Manufacturing Resource Centre. With four decades of engineering expertise, M Naweed Hassan has left a lasting imprint in the engineering realm.",
      },
      {
        id: 2,
        photo: require('../../Photos/HOD_Electrical.jpg'),
        name: "M Tanwess Awan",
        position: "HoD Electronics",
        detail: "Muhammad Tanwees Awan has a DAE in Ground Signaling Technology from the School of Electronics, Pakistan Air Force, and a master's in Political Science from The University of Punjab. He's been the Director of an International School & College since 2021, with technical courses from institutes like PTCL & PAF. With 15 years of experience in managing technical units and a focus on educating out-of-school children, he's making a significant impact in the education sector",
      },
      {
        id: 3,
        photo: require('../../Photos/HOD_CIT.jpg'),
        name: "Engr. Maria Zafar",
        position: "HoD Computer Information Technology and Incharge Academics",
        detail: "Engr Maria Zafar, a Computer Engineering graduate from UET Taxila, has held diverse roles in the educational sector. His experience ranges from serving as an IT Coordination Officer to Vice Principal and eventually Principal in a college. Specializing in industrial robotics, her career reflects a dedication to technological advancement and educational leadership.",
      },
      {
        id: 4,
        photo: require('../../Photos/HOD_Civil.jpg'),
        name: "Mazahir Hussain",
        position: "HoD Civil Technology",
        detail: "Mr. Mazahir Hussain, a distinguished professional with a B.Tech in Civil Technology and an MA in Education, possesses a remarkable track record in the field. With a decade as HOD Civil Technology at Karakuram College of Commerce and Technology in Skardu, along with significant roles as Vice Principal and various instructional positions, his expertise in civil engineering and education shines through. His diverse experience includes international exposure, such as instructing in Sharjah, UAE, and contributing to the National Vocational and Technical Training Commission in Pakistan.",
      },
      {
        id: 5,
        photo: require('../../Photos/HOD_Mechanical.png'),
        name: "M. Naweed Hassan",
        position: "HOD Mechanical",
        detail: "M Naweed Hassan is a mechanical engineering graduate from UET Lahore. He has working experience in Heavy Rebuild Factory Taxila and Corps of Electrical and Mechanical Engineering of the Pakistan Army. His impactful roles encompassed instructional duties at a military college for six years and leadership in the Tank Al Khalid Development project for nearly two decades. Following his retirement in 2010, he joined NUST as a faculty member in the Department of Mechanical Engineering for eleven years, playing a pivotal role in establishing and managing the Manufacturing Resource Centre.",
      },
    ];

      const member = teamMembers.find((member) => member.id === parseInt(id));

      if (!member) {
        return <div>Loading...</div>; // Or handle not found case
      }
    
  return (
    <>
      <Navbar />
      <FloatingButton />
      <section class="section about-section mt-5 pt-5 pb-0 me-0" id="about">
        <div class="container mt-5">
          <div class="d-flex flex-wrap align-items-center justify-content-around flex-row-reverse">
            <div class="col-lg-9 col-12">
              <div class=" lh-lg">
                <h2 class="dark-color fw-bold ms-lg-5 ps-lg-3 mb-4">
                {member.name} - Career Summary
                </h2>
                {/* <h6 class="theme-color lead">A Lead UX &amp; UI designer based in Canada</h6>
                    <p>I <mark>design and develop</mark> services for customers of all sizes, specializing in creating stylish, modern websites, web services and online stores. My passion is to design digital user experiences through the bold interface and meaningful interactions.</p>
                    <div class="row about-list">
                        <div class="col-md-6">
                            <div class="media">
                                <label>Birthday</label>
                                <p>4th april 1998</p>
                            </div>
                            <div class="media">
                                <label>Age</label>
                                <p>22 Yr</p>
                            </div>
                            <div class="media">
                                <label>Residence</label>
                                <p>Canada</p>
                            </div>
                            <div class="media">
                                <label>Address</label>
                                <p>California, USA</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="media">
                                <label>E-mail</label>
                                <p>info@domain.com</p>
                            </div>
                            <div class="media">
                                <label>Phone</label>
                                <p>820-885-3321</p>
                            </div>
                            <div class="media">
                                <label>Skype</label>
                                <p>skype.0404</p>
                            </div>
                            <div class="media">
                                <label>Freelance</label>
                                <p>Available</p>
                            </div>
                        </div> 
                    </div>*/}

                {/* <div class="about-list mb-5 me-0">
                  <p className="fs-5">
                    <b>M. Naweed Hassan</b>, a mechanical engineering graduate
                    from UET Lahore in 1982, has had a distinguished career
                    spanning over four decades:
                  </p>
                  <ul>
                    <li>
                      Started his career at the Heavy Rebuild Factory Taxila.
                    </li>
                    <li>
                      Transitioned to the Corps of Electrical and Mechanical
                      Engineering of the Pak Army, serving for 25 years with
                      diverse assignments and professional training.
                    </li>
                    <li>
                      Held instructional roles at a military college for six
                      years.
                    </li>
                    <li>
                      Led the Tank Al Khalid Development project for nearly two
                      decades, overseeing design, trials, and production, with
                      international engagements.
                    </li>
                    <li>
                      Retired in 2010 and joined NUST as a faculty member in the
                      Department of Mechanical Engineering.
                    </li>
                    <li>
                      Played a pivotal role in establishing and managing the
                      Manufacturing Resource Centre.
                    </li>
                  </ul>
                  <p>
                    With his extensive engineering expertise and impactful
                    contributions, M. Naweed Hassan has made a lasting imprint
                    in the field of engineering. His dedication to innovation,
                    education, and leadership has shaped generations of
                    engineers and continues to influence the industry.
                  </p>
                </div> */}
{/* 
                <h3>{member.position}</h3> */}
                <p className="about-list text-justify mb-5">
                    
                {member.detail}
                </p>
              </div>
            </div>

            {/* <div class=""> */}
              <div class="col-lg-3 about-avatar">
                <img
                  src={member.photo}
                  title=""
                  alt=""
                  className="img-fluid"
                />
              </div>
            {/* </div> */}
          </div>
          {/* <div class="counter">
            <div class="row">
              <div class="col-6 col-lg-3">
                <div class="count-data text-center">
                  <h6 class="count h2" data-to="500" data-speed="500">
                    500
                  </h6>
                  <p class="m-0px font-w-600">Happy Clients</p>
                </div>
              </div>
              <div class="col-6 col-lg-3">
                <div class="count-data text-center">
                  <h6 class="count h2" data-to="150" data-speed="150">
                    150
                  </h6>
                  <p class="m-0px font-w-600">Project Completed</p>
                </div>
              </div>
              <div class="col-6 col-lg-3">
                <div class="count-data text-center">
                  <h6 class="count h2" data-to="850" data-speed="850">
                    850
                  </h6>
                  <p class="m-0px font-w-600">Photo Capture</p>
                </div>
              </div>
              <div class="col-6 col-lg-3">
                <div class="count-data text-center">
                  <h6 class="count h2" data-to="190" data-speed="190">
                    190
                  </h6>
                  <p class="m-0px font-w-600">Telephonic Talk</p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      <JoinUs />
      <Footer />
    </>
  );
}

export default Profiles;
