import React from "react";
import Navbar from "../../components/Navbar";
import FloatingButton from "../../components/Floatingbutton";
import JoinUs from "../../components/JoinUs";
import Footer from "../../components/Footer";

function VocationalCourse() {
  return (
    <>

    <Navbar/>
    <FloatingButton/>
      <div className="container text-center mt-5 pt-5">

        <h2 className="my-5">NEW VOCATIONAL PROGRAMS COMING SOON!</h2>
        <p>Launching <b>November 2024</b>! Stay tuned for exciting vocational courses designed in collaboration with <b>NAVTECH/TEVTA</b></p>
      </div>

      <JoinUs/>
      <Footer/>
    </>
  );
}

export default VocationalCourse;
