import React, { useState } from 'react';
import "../CSS/table.css"

function Table() {
  const data = [
    { Id: '42235', title: 'John Doe',  date: '12-05-2024' },
    { Id: '42442', title: 'Jennifer Smith',  date: '12-05-2024' },
    { Id: '42257', title: 'John Smith',  date: '12-05-2024' },
    { Id: '42311', title: 'John Carpenter', date: '12-05-2024' },
  ];


  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: 'What are the admission requirements for the school?',
      answer: 'Admission requirements vary depending on the grade level and specific program. Generally, requirements include completed application forms, academic transcripts, standardized test scores, letters of recommendation, and a personal statement.',
    },
    {
      question: 'When is the application deadline?',
      answer: "The application deadline typically varies by program and grade level. It's important to check the admissions timeline by contacting the admissions office directly for the most accurate information.",
    },
    {
      question: 'Is financial aid available, and how do I apply for it?',
      answer: 'Yes, financial aid is available for qualifying students. To apply, families must complete the financial aid application form and provide supporting documentation. The deadline for financial aid applications often aligns with the admissions deadline.',
    },
    {
      question: 'Are there opportunities for campus tours or open houses?',
      answer: 'Yes, we offer campus tours, open houses, and information sessions throughout the year. These events provide prospective students and their families with the opportunity to explore our facilities, meet faculty and staff, and learn more about our academic programs.',
    },
    {
      question: 'What is the admissions process for transfer students?',
      answer: 'Transfer student admissions follow a similar process to new student admissions. Transfer students are required to submit transcripts from their current or previous educational institution(s), standardized test scores (if applicable), letters of recommendation, and a personal statement. The admissions committee evaluates transfer applications on a case-by-case basis, considering academic performance and compatibility with our school community.',
    },
  ];

  return (
    <>
    <div className="container">
        <h1 className='my-3'>Announcements</h1>
      <ul className="responsive-table ps-0">
        <li className="table-header-color table-header table-row text-start fw-bold">
          <div className="col col-md-2 col-6 ">Id</div>
          <div className="col col-md-6 col-6">Title</div>
          <div className="col col-md-4 col-6">Date</div>
        </li>
        {data.map((item, index) => (
          <li className="table-row text-start" key={index}>
            <div className="col col-md-2 col-6" data-label="Job Id">{item.Id}</div>
            <div className="col col-md-6 col-6" data-label="Customer Name">{item.title}</div>
            <div className="col col-md-4 col-6 text-danger" data-label="Payment Status">{item.date}</div>
          </li>
        ))}
      </ul>
    </div>


<section className="section margin-top-176-mobile-144">
      <div className="w-layout-blockcontainer container w-container">
        <div className="w-layout-grid text-grid-type-one">
          <div className="wrapper max-width-432">
            <h2 className="h2">Frequently Asked Admissions Questions</h2>
          </div>
          <div className="wrapper accordion-container">
            {faqs.map((faq, index) => (
              <div className="accordion-item" key={index}>
                <div className="accordion-trigger" onClick={() => toggleAccordion(index)}>
                  <div className="regular-xl max-width-680">{faq.question}</div>
                  <div className="accordion-icon" style={{
                    transform: activeIndex === index ? 'rotate(45deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s',
                  }}>
                    <img
                      src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527f0b_Plus.svg"
                      loading="lazy"
                      alt=""
                      className="icon-size-16"
                    />
                  </div>
                </div>
                <div className="accordion-content" style={{
                  height: activeIndex === index ? 'auto' : '0',
                  overflow: 'hidden',
                  transition: 'height 0.3s',
                }}>
                  <p className="regular-xl margin-bottom-24">{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
    </>
  );
}

export default Table;
