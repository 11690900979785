import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../CSS/Career.css";
import "../CSS/Pagination.css";
import FloatingButton from '../components/Floatingbutton'
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import JoinUs from "../components/JoinUs";

function Career() {
  const jobListings = [
    {
      id: 1,
      title: "Web Developer",
      company: "SKANS Institute of Technology",
      location: "Chakro Road, Rawalpindi",
      description: "Good Web Developer",
    },
    {
      id: 2,
      title: "Graphic Designer",
      company: "SKANS Institute of Technology",
      location: "Chakro Road, Rawalpindi",
      description: "Good Graphic Designer",
    },
    {
      id: 3,
      title: "Data Scientist",
      company: "SKANS Institute of Technology",
      location: "USA",
      description: "Experienced Data Scientist",
    },
  ];

  const [currentPage, setCurrentPage] = useState(0);
  const jobsPerPage = 2;

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const offset = currentPage * jobsPerPage;
  const currentJobs = jobListings.slice(offset, offset + jobsPerPage);

  return (
    <>
      <Navbar />
      <FloatingButton/>
      <section class="section">
        <main class="hero-content">
          <div class="w-layout-blockcontainer container hero internal w-container">
            <div class="hero-section-content">
              <div class="hero-section-content-block internal bg-light-orange">
                <div class="wrapper max-width-640-mobile-480">
                  <h1 class=" margin-bottom-28">
                    Fostering Career Excellence, Shaping Future Leaders
                  </h1>
                  <p class="regular-xl max-width-420">
                    At SIT, we empower students with specialized courses and
                    personalized career counseling, preparing them to thrive in
                    competitive industries. We nurture a culture of innovation
                    and entrepreneurship, equipping our graduates to lead with
                    confidence in the global workforce
                  </p>
                </div>
              </div>
              <div class="hero-section-image internal contact"></div>
            </div>
          </div>
        </main>
      </section>

      <section className="container">
        <div className="job-listings">
          <h2 className="text-center mt-5">Latest Job Listings</h2>
          <div className="row">
            {currentJobs.map((job) => (
              <div className="col-md-6" key={job.id}>
                <div className="card">
                  <div className="card-body">
                    <h3 className="card-title">{job.title}</h3>
                    <table className="job-table">
                      <tbody>
                        <tr>
                          <td className="label">Company: </td>
                          <td> {job.company}</td>
                        </tr>
                        <tr>
                          <td className="label">Location: </td>
                          <td> {job.location}</td>
                        </tr>
                        <tr>
                          <td className="label">Description: </td>
                          <td> {job.description}</td>
                        </tr>
                      </tbody>
                    </table>
                    <Link to="/Apply-for-job" className="btn btn-sm">
                      Apply Now
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="pagination-wrapper">
            <ReactPaginate
              previousLabel={"← Previous"}
              nextLabel={"Next →"}
              pageCount={Math.ceil(jobListings.length / jobsPerPage)}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              pageClassName={"page-numbers"}
              activeClassName={"current"}
              previousClassName={"page-numbers prev"}
              nextClassName={"page-numbers next"}
              disabledClassName={"disable"}
            />
          </div>
        </div>

        <div
          class="box-root flex-flex flex-direction--column"
          style={{ flexGrow: "1", zIndex: 0 }}
        >
          <h2 className="my-5 text-center mt-5">Upload your CV here</h2>

          <form id="file-upload-form" class="uploader">
            <input
              id="file-upload"
              type="file"
              name="fileUpload"
              accept="image/*"
            />

            <label for="file-upload" id="file-drag">
              <img id="file-image" src="#" alt="Preview" class="hidden" />
              <div id="start">
                <i class="fa fa-download" aria-hidden="true"></i>
                <div>Select a file or drag here</div>
                <div id="notimage" class="hidden">
                  Please select an image
                </div>
                <span id="file-upload-btn" class="btn btn-primary">
                  Select a file
                </span>
              </div>
              <div id="response" class="hidden">
                <div id="messages"></div>
                <progress class="progress" id="file-progress" value="0">
                  <span>0</span>%
                </progress>
              </div>
            </label>
          </form>
        </div>
      </section>


      <JoinUs/>
      <Footer/>
    </>
  );
}

export default Career;
