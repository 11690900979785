import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Administeration from './Screens/Administration/Administeration';
import ContactUs from './Screens/ContactUs';
import DaeMechanical from './Screens/Programs/DaeMechanical';
import Noticeboard from './Screens/Noticeboard';
import EnquiryForm from './Screens/EnquiryForm';
import Career from './Screens/Career';
import FeeStructure from './Screens/FeeStructure';
import Profiles from './Screens/Administration/Profiles';
import DaeElectrical from './Screens/Programs/DaeElectrical';
import DaeCivil from './Screens/Programs/DaeCivil';
import DaeCIT from './Screens/Programs/DaeCIT';
import VocationalCourse from './Screens/Programs/VocationalCourse';
import PartnerSchoolFeedback from './Screens/News&Events/PartnerSchoolFeedback';
import AdmissionProcedure from './Screens/AdmissionProcedure';
import CoreValue from './Screens/Administration/CoreValue';
import Facts from './Screens/Facts';
import Campus from './Screens/Campus';
import Library from './Screens/Library';
import Projects from './Screens/Projects';
import PartnerSchools from './Screens/PartnerSchools';
import CareerApply from './Screens/CareerApply';
import Function from './Screens/Function';
import Affiliation from './Screens/Affiliation';
import Programs from './Screens/Programs/Programs';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
  },
  {
    path: "/Administeration",
    element: <Administeration/>,
  },
  {
    path: "/ContactUs",
    element: <ContactUs/>,
  },
  {
    path: "/DAE-Mechanical",
    element: <DaeMechanical/>,
  },
  {
    path: "/DAE-Electrical",
    element: <DaeElectrical/>,
  },
  {
    path: "/DAE-Civil",
    element: <DaeCivil/>,
  },
  {
    path: "/DAE-CIT",
    element: <DaeCIT/>,
  },
  {
    path: "/vocational-programs",
    element: <VocationalCourse/>,
  },
  {
    path: "/partner’s-school-feedback",
    element: <PartnerSchoolFeedback/>,
  },

  {
    path: "/Noticeboard",
    element: <Noticeboard/>,
  },
  {
    path: "/Enquiry-Form",
    element: <EnquiryForm/>,
  },
  {
    path: "/Admission-Procedure",
    element: <AdmissionProcedure/>,
  },
  {
    path: "/Career",
    element: <Career/>,
  },
  
  {
    path: "/Fee-Structure",
    element: <FeeStructure/>,
  },
  {
    path: "/Profile/:id",
    element: <Profiles/>,
  },
  {
    path: "/Core-Value",
    element: <CoreValue/>,
  },
  {
    path: "/Facts",
    element: <Facts/>,
  },
  {
    path: "/Campus",
    element: <Campus/>,
  },
  {
    path: "/Library",
    element: <Library/>,
  },
  {
    path: "/Projects",
    element: <Projects/>,
  },

  {
    path: "/Partner-Schools",
    element: <PartnerSchools/>,
  },
  {
    path: "/Apply-for-job",
    element: <CareerApply/>,
  },
    {
    path: "/Functions",
    element: <Function/>,
  },
  {
    path: "/Afiliation",
    element: <Affiliation/>,
  },
  {
    path: "/Programs",
    element: <Programs/>,
  },

]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
