import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import JoinUs from "../components/JoinUs";
import FloatingButton from "../components/Floatingbutton";
import Table from "../components/Table";
import "../CSS/table.css"

function Noticeboard() {
  return (
    <>
    <Navbar/>
    <FloatingButton/>
      <section class="section">
        <main class="hero-content">
          <div class="w-layout-blockcontainer container hero internal w-container">
            <div class="hero-section-content">
              <div class="hero-section-content-block internal bg-light-yellow">
                <div class="wrapper max-width-640-mobile-480">
                  <h1 class=" margin-bottom-28">
                    Stay Connected and Informed
                  </h1>
                  <p class="regular-xl max-width-420">
                    Explore the most recent developments and upcoming events at
                    our school.
                  </p>
                </div>
              </div>
              <div class="hero-section-image internal news-and-events"></div>
            </div>
          </div>
        </main>
      </section>

      <Table/>



      <JoinUs/>
      <Footer/>
    </>
  );
}

export default Noticeboard;
