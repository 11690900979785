import React from 'react'
import { Link } from 'react-router-dom'

function JoinUs() {
  return (
    <section className="w-layout-blockcontainer container w-container">
    <div className="d-flex flex-wrap bg-light-blue p-lg-5 px-4 py-5 mt-5 align-items-center">
      <div className="col-lg-6 col-12 text-lg-end text-start mb-lg-1 mb-3">
        <h2 className="darkblue mb-2" >
          JOIN US
        </h2>
        <p>
          Whether you're a future students, an alumnus, a potential
          faculty or staff member or a proud Floridian there is a home for
          you here
        </p>
      </div>
      <div className="col-lg-6 col-12  d-flex flex-column justify-content-center align-items-center">
        <Link
          className="col-lg-8 col-12 hoverScale  border border-3 rounded-pill text-center  text-white px-lg-5 py-1"
          style={{ backgroundColor: "#1679AB" , textWrap:"nowrap"}}
          to="/Programs"
        >
          <h5 className="fw-bold fs-5" >
            Explore Programs
          </h5>
          <p className="fs-6">Choose DAE program option</p>
        </Link>
        <Link
          className="col-lg-8 col-12 hoverScale  border border-3 rounded-pill text-center  text-white px-5 py-1"
          style={{ backgroundColor: "#1679AB" }}
          to="/career"

        >
          <h5 className="fw-bold fs-5" >
            Work with us
          </h5>
          <p>Discover current job postings</p>
        </Link>
        <Link
          className="col-lg-8 col-12 hoverScale  border border-3 rounded-pill text-center  text-white px-5 py-1"
          style={{ backgroundColor: "#1679AB" }}
        >
          <h5 className="fw-bold fs-5" >
            Join as partner school
          </h5>
          <p>Let us work together</p>
        </Link>
      </div>
    </div>
  </section>
  )
}

export default JoinUs