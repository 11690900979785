import { Link } from "react-router-dom";


const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <>
        <section className="footer mt-5 pt-4">
          {/* margin-top-176-mobile-144 */}
        <div className="w-layout-blockcontainer container w-container ">
        <div className="footer-logo-text">
              <img className="img-fluid mb-3" src={require("../Photos/sit-logo2.png")} alt="Logo" />
            </div>
          <div className="w-layout-grid footer-content-grid">
            <div
              // id="w-node-a131b771-2a49-3f3f-ead4-9cb52dab318c-2dab3189"
              className="footer-block"
            >
              <div className="wrapper">
                <div className="regular-m margin-bottom-16">Institute</div>
                <div className="line black" />
              </div>
              <div className="footer-block-links">
                <Link
                  to="/Administeration"
                  aria-current="page"
                  className="regular-m footer-link w--current"
                >
                  Administeration
                </Link>
                <Link to="/Facts" className="regular-m footer-link">
                Facts
                </Link>
                <Link to="/Campus" className="regular-m footer-link">
                Campus
                </Link>
                <Link to="/news-events" className="regular-m footer-link">
                  Blog
                </Link>
                <Link to="/contact-us" className="regular-m footer-link">
                  Contact Us
                </Link>
                <Link to="/Partner-Schools" className="regular-m footer-link">
                Partner-Schools
                </Link>
              </div>
            </div>
            <div className="footer-block">
              <div className="wrapper">
                <div className="regular-m margin-bottom-16">Academics</div>
                <div className="line black" />
              </div>
              <div className="footer-block-links">
                <Link to="/fee-structure" className="regular-m footer-link">
                Fee structure
          
                </Link> 
                <Link to="/academics/Calender" className="regular-m footer-link">
              Study calendar
          
                </Link>
                <Link
                  to="/library"
                  className="regular-m footer-link"
                >
                  Library
                </Link>
                <Link
                  to="/academics/safe-schools-program"
                  className="regular-m footer-link"
                >
                   Programs
                </Link>
              </div>
            </div>
            <div className="footer-block">
              <div className="wrapper">
                <div className="regular-m margin-bottom-16">Admission</div>
                <div className="line black" />
              </div>
              <div className="footer-block-links">
                <Link to="/enquiry-form" className="regular-m footer-link">
               Enquiry form

          
                </Link> 
                <Link
                  to="/admission-procedure"
                  className="regular-m footer-link"
                >
                 Admission Procedure
                </Link>
                
              </div>
            </div>

            <div className="footer-block">
              <div className="wrapper">
                <div className="regular-m margin-bottom-16">News & Events</div>
                <div className="line black" />
              </div>
              <div className="footer-block-links">
                <Link to="/Functions" className="regular-m footer-link">
                Functions

                </Link> 
                <Link to="/academics/departments" className="regular-m footer-link">
                Few videos
          
                </Link>
                <Link
                  to="/partner’s-school-feedback"
                  className="regular-m footer-link"
                >
                 Partner’s school feedback
                </Link>
                <Link
                  to="/projects"
                  className="regular-m footer-link"
                >
                 Projects
                </Link>

                <Link
                  to="/Noticeboard"
                  className="regular-m footer-link"
                >
                 Noticeboard
                </Link>
                
              </div>
            </div>
            <div
              // id="w-node-a131b771-2a49-3f3f-ead4-9cb52dab31a8-2dab3189"
              className="footer-block"
            >
              <div className="wrapper">
                <div className="regular-m margin-bottom-16">Follow Us</div>
                <div className="line black" />
              </div>
              <div className="footer-block-links socials">
                <a
                  href="https://www.facebook.com/"
                  target="_blank"
                  className="regular-m footer-link"
                >
                  Facebook
                </a>
                <a
                  href="https://www.youtube.com/"
                  target="_blank"
                  className="regular-m footer-link"
                >
                  YouTube
                </a>
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                  className="regular-m footer-link"
                >
                  Instagram
                </a>
              </div>
            </div>
          </div>
          <div className="footer-legal text-center">
            <div className="regular-s  ">
              © {currentYear}, All Rights Reserved
            </div>
          </div>
        </div>
      </section>
      </>
    );
};

export default Footer;