import React from 'react'
import "../../CSS/CoreValue.css"
import Navbar from '../../components/Navbar'
import FloatingButton from '../../components/Floatingbutton'
import JoinUs from '../../components/JoinUs'
import Footer from '../../components/Footer'
function CoreValue() {
  return (
    <>

    <Navbar/>
    <FloatingButton/>
     <section className="section py-3">
        <main className="hero-content">
          <div className="w-layout-blockcontainer container hero internal w-container">
            <div className="hero-section-content">
              <div className="hero-section-content-block internal bg-light-red">
                <div className="wrapper max-width-640-mobile-480">
                  <h1 className="margin-bottom-28">
                  Excellence, Innovation, Community
                    {/* Our Leaders, Your Guides */}
                  </h1>
                  <p className="regular-xl max-width-420">
                  At SKANS Institute of Technology, we champion excellence, drive innovation, and foster a strong sense of community. We empower our community to achieve greatness through education, innovation, and collaborative spirit.
                  </p>
                </div>
              </div>
              <div className="hero-section-image internal advanced-program"></div>
            </div>
          </div>
        </main>
      </section>
    <div class="container ">
        <h1 className='my-5'>Our Core Values</h1>
        <div className='d-flex justify-content-between flex-lg-nowrap flex-wrap'>
    <section class="core-value col-lg-4 col-12">
      <div class="core-value-content">
        <h2 className='fs-1'>Excellence</h2>
        <p><span className='fs-4'>Strive methodically as an Institution to bring out the best in everyone.</span> <br></br>Excellence comes through continuous improvement and acceptance of the fact that there is always more to be done to achieve greater heights in quality and performance.</p>
      </div>
    </section>
    <section class="core-value col-lg-4 col-12 mx-2">
      <div class="core-value-content">
      <h2 className='fs-1'>Innovation and Invention</h2>
        <p><span className='fs-4'>Explore the uncharted frontiers of knowledge to contribute to the world to make it better through innovation and Invention.</span><br></br> Core targets are achieved by creative knowledge when practiced passionately. We inculcate creative and transformative changes in thinking and approach.</p>
      </div>
    </section>
    <section class="core-value col-lg-4 col-12">
      <div class="core-value-content">
      <h2 className='fs-1'>Community</h2>
        <p><span className='fs-4'>Create a positive sense of belonging and togetherness through involvement and outreach.</span><br></br> We work together toward the betterment of our local community around us. We utilize our intellect, creativity, skills, strength, and compassion to create a supportive community that serves the common good.</p>
      </div>
    </section>
  </div>
  </div>

  <JoinUs/>
  <Footer/>

    </>
  )
}

export default CoreValue