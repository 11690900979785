import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import FloatingButton from '../components/Floatingbutton'
import Footer from '../components/Footer'
import ReactPaginate from 'react-paginate';
import "../CSS/Function.css"

function Function() {
    const images = [
        { id: 1, src: require("../Photos/gallery1.jpg") },
        { id: 2, src: require("../Photos/gallery2.jpg") },
        { id: 3, src: require("../Photos/gallery3.jpg")},
        { id: 4, src: require("../Photos/gallery4.jpg") },
        { id: 5, src: require("../Photos/gallery5.jpg") },
        { id: 6, src: require("../Photos/gallery6.jpg") },
        { id: 7, src: require("../Photos/gallery7.jpg") },
        { id: 8, src: require("../Photos/gallery8.jpg")},
        { id: 9, src: require("../Photos/gallery9.jpg")},
        { id: 10, src: require("../Photos/gallery10.jpg") },
        // Add more images if needed...
      ];


      const [currentPage, setCurrentPage] = useState(0);
      const imagesPerPage = 6;
    
      const handlePageClick = (data) => {
        setCurrentPage(data.selected);
      };
    
      const offset = currentPage * imagesPerPage;
      const currentImages = images.slice(offset, offset + imagesPerPage);
    
      // Dynamically assign grid row spans
      const getGridRowSpan = (index) => {
        const spans = [
          [6, 11],
          [6, 7],
          [6, 9],
          [7, 12],
          [9, 13],
          [11, 13],
        ];
        return spans[index % spans.length];
      };
  return (
    <>
    <Navbar/>
    <FloatingButton/>
    <section className="section pt-3 mb-0 pb-0">
        <main className="hero-content">
          <div className=" container hero internal">
            <div className="hero-section-content">
              <div className="hero-section-content-block internal bg-light-red">
                <div className="wrapper max-width-640-mobile-480">
                  <h1 className="margin-bottom-28">
                  Skans Gallery Events: Where Every Moment Finds Its Timeless Frame
                    {/* Our Leaders, Your Guides */}
                  </h1>
                  <p className="regular-xl max-width-420">
                  At Skans Gallery Events, we curate more than just visuals; we craft stories that unfold with every click, capturing the essence of your unforgettable moments in exquisite detail.
                  </p>
                </div>
              </div>
              <div className="hero-section-image internal Library-background"></div>
            </div>
          </div>
        </main>
      </section>
    <main className="container-gallery">
        {currentImages.map((image, index) => {
          const [start, end] = getGridRowSpan(index);
          return (
            <div key={image.id} className={`item-${image.id}`} style={{ gridRow: `${start} / ${end}` }}>
              <img className="img-gallery" src={image.src} alt="" />
            </div>
          );
        })}
      </main>

      <div className="pagination-wrapper">
        <ReactPaginate
          previousLabel={"← Previous"}
          nextLabel={"Next →"}
          pageCount={Math.ceil(images.length / imagesPerPage)}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          pageClassName={"page-numbers"}
          activeClassName={"current"}
          previousClassName={"page-numbers prev"}
          nextClassName={"page-numbers next"}
          disabledClassName={"disable"}
        />
      </div>

    <Footer/>
    </>
  )
}

export default Function