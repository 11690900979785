import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import JoinUs from "../../components/JoinUs";
import FloatingButton from "../../components/Floatingbutton";
function DaeElectrical() {
  return (
    <>
      <Navbar />
      <FloatingButton />

      <section class="section">
        <main class="hero-content">
          <div class="w-layout-blockcontainer container hero internal w-container">
            <div class="hero-section-content">
              <div class="hero-section-content-block internal bg-light-red">
                <div class="wrapper max-width-640-mobile-480">
                  <h1 class=" margin-bottom-28">
                    Elevate Your Advanced Education
                  </h1>
                  <p class="regular-xl max-width-420">
                    Challenge yourself with rigorous coursework, expand your
                    knowledge, and prepare for success in college and beyond.
                  </p>
                </div>
              </div>
              <div class="hero-section-image internal advanced-program"></div>
            </div>
          </div>
        </main>
      </section>

      <div class="container mt-5 lh-lg">
        <header className="text-center fw-bold">
          <h2>
            Diploma of Associate Engineering (DAE) in Electrical Technology
          </h2>
        </header>

        <section class="mt-5">
          <p>
            Electrical technology is a field that deals with the design,
            application, installation, manufacturing, operation, or maintenance
            of electrical systems. It encompasses a wide range of areas,
            including power generation, transmission, distribution, and
            utilization, as well as electronics and telecommunications.
          </p>
        </section>

        <section class="mt-5">
          <h3>Laboratories</h3>
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Lab</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>Power Lab</strong>
                </td>
                <td>
                  Where students can experiment with power generation,
                  transmission, and distribution systems.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Wiring Lab</strong>
                </td>
                <td>
                  Where students learn about electrical wiring and installation
                  techniques.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Basic Electric Lab</strong>
                </td>
                <td>
                  Where fundamental electrical principles are taught and
                  practiced.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Computer Lab</strong>
                </td>
                <td>
                  Equipped with software for simulation and design of electrical
                  systems.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Physics Lab</strong>
                </td>
                <td>
                  Supporting the understanding of electrical principles through
                  practical experiments.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Chemistry Lab</strong>
                </td>
                <td>
                  Providing a foundation in materials science relevant to
                  electrical technology.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Wood Work Lab</strong>
                </td>
                <td>
                  Where students learn about the construction and maintenance of
                  wooden structures used in electrical installations.
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <section className="d-flex flex-md-nowrap flex-wrap justify-content-around mt-5 mb-2">
          <div className="col-md-4 col-12">
            <img className="img-fluid" src={require("../../Photos/Electrical_lab.jpg")}></img>
          </div>
          <div className="col-md-4 col-12 mx-md-2 my-md-0 my-2">
            <img className="img-fluid" src={require("../../Photos/Electrical_lab_1.jpg")}></img>
          </div>
          <div className="col-md-4 col-12">
            <img className="img-fluid" src={require("../../Photos/Electrical_lab_2.jpg")}></img>
          </div>
        </section>
        <section className="d-flex flex-md-nowrap flex-wrap justify-content-around mb-5 ">
          <div className="col-md-4 col-12">
            <img className="img-fluid" src={require("../../Photos/Electrical_lab_3.jpg")}></img>
          </div>
          <div className="col-md-4 col-12 mx-md-2 my-md-0 my-2">
            <img className="img-fluid" src={require("../../Photos/Electrical_lab_4.jpg")}></img>
          </div>
          <div className="col-md-4 col-12">
            <img className="img-fluid" src={require("../../Photos/Electrical_lab_5.jpg")}></img>
          </div>
        </section>

        <section class="mt-5">
          <h2>Scheme of Study for Electrical Technology</h2>
          <div class=" mt-5 " style={{ overflowX: "auto" }}>
            <h2 class="">FIRST YEAR</h2>
            <table class="table table-striped table-bordered table-bordered">
              <thead>
                <tr>
                  <th>SNO</th>
                  <th>1st Year</th>
                  <th>Code</th>
                  <th>Subject</th>
                  <th>T</th>
                  <th>P</th>
                  <th>C</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Gen</td>
                  <td>111</td>
                  <td>Islamyat & Pak Studies</td>
                  <td>1</td>
                  <td>0</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Eng</td>
                  <td>112</td>
                  <td>English</td>
                  <td>2</td>
                  <td>0</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Math</td>
                  <td>123</td>
                  <td>Applied Mathematics-1</td>
                  <td>3</td>
                  <td>0</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Chem</td>
                  <td>132</td>
                  <td>Applied Chemistry</td>
                  <td>1</td>
                  <td>3</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Comp</td>
                  <td>162</td>
                  <td>Computer Applications</td>
                  <td>1</td>
                  <td>3</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>ET</td>
                  <td>125</td>
                  <td>Principles of Electrical Engineering</td>
                  <td>3</td>
                  <td>6</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>ET</td>
                  <td>131</td>
                  <td>Basic Electrical Drawing</td>
                  <td>0</td>
                  <td>3</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>ET</td>
                  <td>143</td>
                  <td>Workshop Practice (Electrical)</td>
                  <td>1</td>
                  <td>6</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>ET</td>
                  <td>153</td>
                  <td>Workshop Practice (Mechanical) - Metal Work</td>
                  <td>0</td>
                  <td>3</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Welding</td>
                  <td>0</td>
                  <td>3</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Machine Shop</td>
                  <td>0</td>
                  <td>3</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <strong>Total</strong>
                  </td>
                  <td>
                    <strong>24</strong>
                  </td>
                  <td>
                    <strong>30</strong>
                  </td>
                  <td>
                    <strong>22</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="mt-5" style={{ overflowX: "auto" }}>
            <h2 class="">SECOND YEAR</h2>
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SNO</th>
                  <th>1st Year</th>
                  <th>Code</th>
                  <th>Subject</th>
                  <th>T</th>
                  <th>P</th>
                  <th>C</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Gen</td>
                  <td>211</td>
                  <td>Islamyat & Pak Studies</td>
                  <td>1</td>
                  <td>0</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Math</td>
                  <td>233</td>
                  <td>Applied Mathematics-II</td>
                  <td>3</td>
                  <td>0</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Phy</td>
                  <td>222</td>
                  <td>Applied Physics</td>
                  <td>1</td>
                  <td>3</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>MGM</td>
                  <td>221</td>
                  <td>Business Management & Industrial Economics</td>
                  <td>1</td>
                  <td>0</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>ET</td>
                  <td>203</td>
                  <td>D.C Machine & Batteries</td>
                  <td>2</td>
                  <td>3</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>ET</td>
                  <td>273</td>
                  <td>Electrical Instruments & Measurements</td>
                  <td>2</td>
                  <td>3</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>ET</td>
                  <td>253</td>
                  <td>Utilization of Electrical Energy</td>
                  <td>2</td>
                  <td>3</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>ET</td>
                  <td>252</td>
                  <td>Electrical Installation Planning & Estimating</td>
                  <td>1</td>
                  <td>3</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>ET</td>
                  <td>261</td>
                  <td>Application of Computers in Electrical Technology</td>
                  <td>0</td>
                  <td>3</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>ET</td>
                  <td>283</td>
                  <td>Basic Electronics</td>
                  <td>2</td>
                  <td>3</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>11</td>
                  <td>ET</td>
                  <td>282</td>
                  <td>Digital Logic Design</td>
                  <td>1</td>
                  <td>3</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <strong>Total</strong>
                  </td>
                  <td>
                    <strong>16</strong>
                  </td>
                  <td>
                    <strong>24</strong>
                  </td>
                  <td>
                    <strong>24</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class=" mt-5" style={{ overflowX: "auto" }}>
            <h2 class="">THIRD YEAR</h2>
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SNO</th>
                  <th>1st Year</th>
                  <th>Code</th>
                  <th>Subject</th>
                  <th>T</th>
                  <th>P</th>
                  <th>C</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Gen</td>
                  <td>311</td>
                  <td>Islamyat & Pak Studies</td>
                  <td>1</td>
                  <td>0</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>MGM</td>
                  <td>321</td>
                  <td>Business Communication</td>
                  <td>1</td>
                  <td>0</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>MGM</td>
                  <td>311</td>
                  <td>Industrial Management & Human Relations</td>
                  <td>1</td>
                  <td>0</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>ET</td>
                  <td>326</td>
                  <td>A.C Machines</td>
                  <td>4</td>
                  <td>6</td>
                  <td>6</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>ET</td>
                  <td>332</td>
                  <td>Power Plant & Energy Conversation</td>
                  <td>2</td>
                  <td>0</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>ET</td>
                  <td>345</td>
                  <td>
                    Transmission, Distribution & Protection of Electrical
                    Systems
                  </td>
                  <td>4</td>
                  <td>3</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>ET</td>
                  <td>303</td>
                  <td>Telecommunication</td>
                  <td>2</td>
                  <td>3</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>ET</td>
                  <td>363</td>
                  <td>Repair & Maintenance of Electrical Equipment</td>
                  <td>1</td>
                  <td>6</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>ET</td>
                  <td>373</td>
                  <td>Industrial Electronics</td>
                  <td>2</td>
                  <td>3</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <strong>Total</strong>
                  </td>
                  <td>
                    <strong>18</strong>
                  </td>
                  <td>
                    <strong>21</strong>
                  </td>
                  <td>
                    <strong>25</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        <section class="my-5">
          <h2>Career Opportunities</h2>
          <p>
            A Diploma of Associate Engineering (DAE) in Electrical Technology
            offers diverse career paths such as electrical technician,
            maintenance supervisor, technical support specialist, and automation
            technician, with opportunities for further education in electrical
            engineering for advanced roles.
          </p>
        </section>
      </div>

      <JoinUs />
      <Footer />
    </>
  );
}

export default DaeElectrical;
