import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import JoinUs from "../components/JoinUs";
import FloatingButton from "../components/Floatingbutton";

function ContactUs() {
    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission logic here
      };
  return (
    <>
      <Navbar />
      <FloatingButton/>
      <section class="section">
        <main class="hero-content">
          <div class="w-layout-blockcontainer container hero internal w-container">
            <div class="hero-section-content">
              <div class="hero-section-content-block internal bg-light-orange">
                <div class="wrapper max-width-640-mobile-480">
                  <h1 class=" margin-bottom-28">
                    SKANS Institute of Technology Contacts
                  </h1>
                  <p class="regular-xl max-width-420">
                    Whether you're a prospective student, parent, alumni, or
                    community member, we value your feedback and are eager to
                    assist you.
                  </p>
                </div>
              </div>
              <div class="hero-section-image internal contact"></div>
            </div>
          </div>
        </main>
      </section>

      <section class="section margin-top-176-mobile-144">
        <div class="w-layout-blockcontainer container w-container">
          <h2 class="h2 max-width-432-mobile-320 margin-bottom-56">
            Stay Connected With The Institute
          </h2>
          <div class="w-layout-grid blocks-grid-3-tablet-1-mobile-1">
            <a
              href="https://maps.app.goo.gl/TJ4G8YCvJGNSH9fs7"
              target="_blank"
              class="block padding-24-32 justify-content bg-grey-3 w-inline-block"
            >
              <div class="wrapper max-width-420-tablet-640 width-100 margin-bottom-32">
                <h4 class="h4 margin-bottom-20">Visit Us</h4>
                <div class="line black margin-bottom-20"></div>
                <p class="regular-m">
                  Come and visit us at SKANS Institute of Technology, conveniently
                  located in Toronto. We look forward to welcoming you to our
                  vibrant school community.
                </p>
              </div>
              <div class="tertiary-button">
                <div class="regular-m">View On Map</div>
                <div class="tertiary-button-icon">
                  <img
                    src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac5c1d21f3b536e04f_Arrow%20Right.svg"
                    loading="lazy"
                    alt=""
                    class="icon-size-14"
                  />
                </div>
              </div>
            </a>
            <a
              href="mailto:info@sit.pk"
              class="block padding-24-32 justify-content bg-grey-3 w-inline-block"
            >
              <div class="wrapper max-width-420-tablet-640 width-100 margin-bottom-32">
                <h4 class="h4 margin-bottom-20">Email Us</h4>
                <div class="line black margin-bottom-20"></div>
                <p class="regular-m">
                  Whether you are a prospective student, parent, or community
                  member, we are here to provide you with the information you
                  seek.
                </p>
              </div>
              <div class="tertiary-button">
                <div class="regular-m">Send Email</div>
                <div class="tertiary-button-icon">
                  <img
                    src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac5c1d21f3b536e04f_Arrow%20Right.svg"
                    loading="lazy"
                    alt=""
                    class="icon-size-14"
                  />
                </div>
              </div>
            </a>
            <a
              href="tel:+923325224538"
              class="block padding-24-32 justify-content bg-grey-3 w-inline-block"
            >
              <div class="wrapper max-width-420-tablet-640 width-100 margin-bottom-32">
                <h4 class="h4 margin-bottom-20">Call Us</h4>
                <div class="line black margin-bottom-20"></div>
                <p class="regular-m">
                  Contacting SKANS Institute of Technology is just a phone call
                  away. We value open communication and are ready to assist you.
                </p>
              </div>
              <div class="tertiary-button">
                <div class="regular-m">Call Now</div>
                <div class="tertiary-button-icon">
                  <img
                    src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac5c1d21f3b536e04f_Arrow%20Right.svg"
                    loading="lazy"
                    alt=""
                    class="icon-size-14"
                  />
                </div>
              </div>
            </a>
          </div>
        </div>
      </section>

      <section className="section margin-top-176-mobile-144">
      <div className="w-layout-blockcontainer container w-container">
        <div className="banner-type-three">
          <div className="banner-type-three-content bg-light-yellow">
            <div className="wrapper max-width-600">
              <h2 className="h2 max-width-432-mobile-320 margin-bottom-24">
                Reach Out to Us
              </h2>
              <p className="regular-l">
                Whether you have questions, feedback, or inquiries, we welcome
                your messages. Fill out the form below with your contact
                information and message, and our dedicated team will respond
                to you promptly.
              </p>
            </div>
            <div className="form-block w-form">
              <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                onSubmit={handleSubmit}
                className="form"
                data-wf-page-id="65e86a7fd8c611f8bbd71edf"
                data-wf-element-id="c49f58d5-c632-2bab-5020-32169c8072f5"
                aria-label="Email Form"
              >
                <input
                  className="form-input w-input"
                  maxLength="256"
                  name="Full-Name"
                  data-name="Full Name"
                  placeholder="Full Name"
                  type="text"
                  id="Full-Name"
                  required
                />
                <input
                  className="form-input w-input"
                  maxLength="256"
                  name="Email"
                  data-name="Email"
                  placeholder="Email"
                  type="email"
                  id="Email"
                  required
                />
                <input
                  className="form-input w-input"
                  maxLength="256"
                  name="Phone"
                  data-name="Phone"
                  placeholder="Phone"
                  type="tel"
                  id="Phone"
                />
                <input
                  className="form-input last w-input"
                  maxLength="256"
                  name="Message"
                  data-name="Message"
                  placeholder="What interests you?"
                  type="text"
                  id="Message"
                  required
                />
                <input
                  type="submit"
                  data-wait="Please wait..."
                  className="button primary small w-button"
                  value="Send Message"
                />
              </form>
              <div
                className="success-message w-form-done"
                tabIndex="-1"
                role="region"
                aria-label="Email Form success"
              >
                <div className="success-message-content">
                  <div className="success-message-icon">
                    <img
                      src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e8751606d530badf35145d_Check.svg"
                      loading="lazy"
                      alt=""
                      className="icon-size-20"
                      style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}
                    />
                  </div>
                  <div className="wrapper width-100 max-width-420">
                    <h4 className="h4 margin-bottom-12">
                      Your Message Has Been Sent
                    </h4>
                    <p>
                      You can expect a response within 24 hours. Stay tuned
                      for some fantastic news!
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="error-message w-form-fail"
                tabIndex="-1"
                role="region"
                aria-label="Email Form failure"
              >
                <div className="regular-m">
                  Oops! Something went wrong while submitting the form.
                </div>
              </div>
            </div>
          </div>
          <div className="banner-type-three-image">
            <img
              src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e870ed40079fc76edd2f69_Image-form.webp"
              loading="lazy"
              sizes="(max-width: 767px) 90vw, (max-width: 991px) 87vw, 45vw"
              alt="Man studying"
              className="parallax-image"
              style={{ willChange: 'transform', transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}
            />
          </div>
        </div>
      </div>
    </section>

    <div className="container">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53204.80636778993!2d72.92911291122442!3d33.54557064260562!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df934e7554ade3%3A0xebef25df5484c84e!2sSKANS%20Institute%20of%20Technology%20(SIT)!5e0!3m2!1sen!2s!4v1720119601649!5m2!1sen!2s" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>

    <JoinUs/>
      <Footer />
    </>
  );
}

export default ContactUs;
