import React from 'react'
import Navbar from '../components/Navbar'
import FloatingButton from '../components/Floatingbutton'
import JoinUs from '../components/JoinUs'
import Footer from '../components/Footer'

function Affiliation() {
  return (
    <>
    <Navbar/>
    <FloatingButton/>
    <section className="section py-3">
        <main className="hero-content">
          <div className=" container hero internal">
            <div className="hero-section-content">
              <div className="hero-section-content-block internal bg-light-red">
                <div className="wrapper max-width-640-mobile-480">
                  <h1 className="margin-bottom-28">
                  Affiliations
                    {/* Our Leaders, Your Guides */}
                  </h1>
                  <p className="regular-xl max-width-420">
                  At SIT, our affiliations include recognition by the Federal Board of Secondary and Intermediate Education for technical courses. We are currently in the process of affiliation with PSDA, PBT, and NAVTTC, ensuring our commitment to excellence in every aspect of our services
                  </p>
                </div>
              </div>
              <div className="hero-section-image internal advanced-program"></div>
            </div>
          </div>
        </main>
      </section>
      <div className='container mt-5 lh-lg'>
    <h2>Affiliations</h2>
    <ul>
        <li>AFFILIATED WITH FEDERAL BOARD OF SECONDARY AND INTERMEDIATE EDUCATION (Technical courses)</li>
        <li>PSDA - UNDER PROCESS</li>
        <li>PBT - UNDER PROCESS</li>
        <li>NAVTTC - UNDER PROCESS</li>
    </ul>
</div>


    <JoinUs/>
    <Footer/>
    
    </>
  )
}

export default Affiliation