import React from 'react'
import Navbar from '../components/Navbar'
import FloatingButton from '../components/Floatingbutton'
import JoinUs from '../components/JoinUs'
import Footer from '../components/Footer'

function PartnerSchools() {
  return (
    <>


    <Navbar/>
    <FloatingButton/>


    <section className="section py-3">
        <main className="hero-content">
          <div className=" container hero internal">
            <div className="hero-section-content">
              <div className="hero-section-content-block internal bg-green">
                <div className="wrapper max-width-640-mobile-480">
                  <h1 className="margin-bottom-28">
                  Empowering Education with Expert Faculty
                    {/* Our Leaders, Your Guides */}
                  </h1>
                  <p className="regular-xl max-width-420">
                  At SIT, we pride ourselves on having a faculty and teaching staff of exceptional stature. Our selection criteria prioritize qualifications and experience, ensuring the highest standards of education.
                  </p>
                </div>
              </div>
              <div className="hero-section-image internal advanced-program"></div>
            </div>
          </div>
        </main>
      </section>
    <div class="container mt-5 mb-3">
  <div class="marquee-container">
    <div class="row">
      <h2>Partner's School</h2>
      <div class="col">
        <div class="marquee-content">
          <img className='img-fluid me-2' src={require("../Photos/Glorius_logo.jpg")} alt="Image 1" class="marquee-item"/>
          <img className='img-fluid me-2' src={require("../Photos/lighthouse_logo.jfif")} alt="Image 2" class="marquee-item"/>
          <img className='img-fluid me-2' src={require("../Photos/RightSchool_logo.png")} alt="Image 3" class="marquee-item"/>
          <img className='img-fluid me-2' src={require("../Photos/Glorius_logo.jpg")} alt="Image 4" class="marquee-item"/>
          <img className='img-fluid me-2' src={require("../Photos/Glorius_logo.jpg")} alt="Image 1" class="marquee-item"/>
          <img className='img-fluid me-2' src={require("../Photos/lighthouse_logo.jfif")} alt="Image 2" class="marquee-item"/>
          <img className='img-fluid me-2' src={require("../Photos/RightSchool_logo.png")} alt="Image 3" class="marquee-item"/>
          <img className='img-fluid me-2' src={require("../Photos/Glorius_logo.jpg")} alt="Image 4" class="marquee-item"/>
          <img className='img-fluid me-2' src={require("../Photos/Glorius_logo.jpg")} alt="Image 1" class="marquee-item"/>
          <img className='img-fluid me-2' src={require("../Photos/lighthouse_logo.jfif")} alt="Image 2" class="marquee-item"/>
          <img className='img-fluid me-2' src={require("../Photos/RightSchool_logo.png")} alt="Image 3" class="marquee-item"/>
          <img className='img-fluid me-2' src={require("../Photos/Glorius_logo.jpg")} alt="Image 4" class="marquee-item"/>
        </div>
      </div>
    </div>
  </div>
</div>

    <JoinUs/>
    <Footer/>
    </>
  )
}

export default PartnerSchools