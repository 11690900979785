import React from "react";
import Navbar from "../../components/Navbar";
import FloatingButton from "../../components/Floatingbutton";
import JoinUs from "../../components/JoinUs";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";

function Programs() {
  return (
    <>
      <Navbar />
      <FloatingButton />

      <section class="section">
        <main class="hero-content">
          <div class="w-layout-blockcontainer container hero internal w-container">
            <div class="hero-section-content">
              <div class="hero-section-content-block internal bg-light-red">
                <div class="wrapper max-width-640-mobile-480">
                  <h1 class=" margin-bottom-28">
                    Discover Your Path to Success
                  </h1>
                  <p class="regular-xl max-width-420">
                    Explore our comprehensive programs, designed to equip you
                    with the skills and knowledge to thrive in the fields of
                    engineering and technology.
                  </p>
                </div>
              </div>
              <div class="hero-section-image internal advanced-program"></div>
            </div>
          </div>
        </main>
      </section>
      <div class="container mt-5 lh-lg">

        <div class="section-content">
          <h2 className="mb-5">Diploma of Associate Engineering (DAE)</h2>

          <div className="mb-4 d-flex flex-wrap align-items-center justify-content-around">
            <div className="col-lg-5 col-12">
              <h3 className="mb-2">DAE Civil</h3>
              <p className="fs-5 text-justify">
                The Diploma of Associate Engineering (DAE) in Civil Technology
                at Skans Institute of Technology prepares students for careers
                in civil engineering. Students gain expertise in planning,
                designing, executing, and maintaining various civil engineering
                projects. This includes infrastructure like buildings, dams,
                railways, airports, roads, and more. With hands-on training and
                theoretical knowledge, graduates are equipped to contribute
                effectively to the construction and infrastructure sectors.
              </p>
              <Link to="/dae-civil" className="btn btn-success">View Detail</Link>
            </div>
            <div className="col-lg-5 col-12">
              <img
                className="img-fluid rounded"
                src={require("../../Photos/Civil_lab_1.jpg")}
              ></img>
            </div>
          </div>

          <div className="mb-4 d-flex  flex-wrap align-items-center justify-content-around">
            <div className="col-lg-5 col-12 order-lg-0 order-1">
              <img
                className="img-fluid rounded"
                src={require("../../Photos/Electrical_lab_1.jpg")}
              ></img>
            </div>
            <div className="col-lg-5 col-12 order-lg-1 order-0">
              <h3 className="mb-2">DAE Electrical</h3>
              <p  className="fs-5 text-justify">
                The DAE Electrical Technology program focuses on the
                comprehensive understanding and practical application of
                electrical systems. Students learn about designing, installing,
                manufacturing, operating, and maintaining electrical equipment
                and systems. The curriculum is designed to meet industry
                demands, ensuring graduates have the skills needed to succeed in
                diverse fields such as power generation, manufacturing,
                telecommunications, and more.
              </p>            
              <Link to="/dae-electrical" className="btn btn-success mx-0">View Detail</Link>
            </div>

          </div>

          <div className="mb-4 d-flex flex-wrap align-items-center justify-content-around">
            <div className="col-lg-5 col-12">
            <h3 className="mb-2">DAE Mechanical</h3>
            <p  className="fs-5 text-justify">
              DAE Mechanical Technology at Skans Institute of Technology
              emphasizes the application of engineering principles in the
              development and production of mechanical systems and products.
              Students learn about machine design, manufacturing processes,
              materials science, and automation technologies. Practical training
              in state-of-the-art labs and workshops prepares graduates for
              careers in industries ranging from automotive and aerospace to
              manufacturing and energy.
            </p>
            <Link to="/dae-mechanical" className="btn btn-success mx-0">View Detail</Link>

          </div>
          <div className="col-lg-5 col-12">
              <img
                className="img-fluid rounded"
                src={require("../../Photos/Mechanical_lab1.jpg")}
              ></img>
            </div>
          </div>
          <div className="mb-4 d-flex flex-wrap align-items-center justify-content-around">
          <div className="col-lg-5 col-12 order-lg-0 order-1">
              <img
                className="img-fluid rounded"
                src={require("../../Photos/Cit-pic1.jpeg")}
              ></img>
            </div>
          <div class="mb-4 col-lg-5 col-12 order-lg-1 order-0">
            <h3 className="mb-2">DAE CIT (Computer Information Technology)</h3>
            <p className="fs-5 text-justify">
              The DAE in Computer Information Technology (CIT) equips students
              with the knowledge and skills to manage and optimize computer
              systems within organizations. Coursework covers computer networks,
              database management, programming languages, and system
              administration. With a focus on practical problem-solving and
              industry-relevant projects, graduates are prepared for roles in IT
              support, software development, network administration, and more.
            </p> 
            <Link to="/dae-CIT" className="btn btn-success mt-1">View Detail</Link>
          </div>
         
        </div>
        </div>
        <div class="section-content">
          <h2 class="section-heading">Vocational Programs</h2>
          <p>
            Skans Institute of Technology will introduce Vocational Programs
            starting in November 2024. These programs are designed in
            collaboration with NAVTECH / TEVTA to meet the specific skill
            demands of industries. The exact courses offered will be announced
            soon, catering to the evolving needs of the job market and providing
            students with practical, job-ready skills.
          </p>
        </div>

        <div class="section-content text-justify">
          <h2 class="mb-3">
            Why Choose Skans Institute of Technology?
          </h2>
          <ul class="list-unstyled">
            <li className="mb-3">
              <strong>Industry-Driven Curriculum:</strong> Our programs are
              designed in consultation with industry experts to ensure relevance
              and meet current industry standards.
            </li>
            <li className="mb-3">
              <strong>State-of-the-Art Facilities:</strong> Students have access
              to modern laboratories, workshops, and specialized equipment that
              enhance learning and practical skills development.
            </li>
            <li className="mb-3">
              <strong>Experienced Faculty:</strong> Learn from experienced
              instructors who bring real-world expertise into the classroom,
              providing valuable insights and mentorship.
            </li>
            <li className="mb-3">
              <strong>Career Opportunities:</strong> Skans Institute of
              Technology maintains strong connections with industry partners,
              helping students secure internships and job placements upon
              graduation.
            </li>
          </ul>
        </div>

        {/* <div class="admission-info mt-4">
          <h2>Admission Information</h2>
          <p>
            For detailed admission requirements, application deadlines, and how
            to apply, please visit our{" "}
            <a href="#" class="text-primary">
              Admissions page
            </a>
            .
          </p>
        </div> */}
      </div>

      <JoinUs />
      <Footer />
    </>
  );
}

export default Programs;
