import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import FloatingButton from "../components/Floatingbutton";
import JoinUs from "../components/JoinUs";
import Footer from "../components/Footer";
import CountUp, { useCountUp } from 'react-countup';
import { useInView } from 'react-intersection-observer';
import ScrollTrigger from "react-scroll-trigger"
function Facts() {
  const [counton, setCounton] = useState(false);
  return (
    <>
      <Navbar />
      <FloatingButton />

 
      <section className="section py-3">
        <main className="hero-content">
          <div className=" container hero internal">
            <div className="hero-section-content">
              <div className="hero-section-content-block internal bg-light-red">
                <div className="wrapper max-width-640-mobile-480">
                  <h1 className="margin-bottom-28">
                    Empowering Education with Expert Faculty
                    {/* Our Leaders, Your Guides */}
                  </h1>
                  <p className="regular-xl max-width-420">
                    At SIT, we pride ourselves on having a faculty and teaching
                    staff of exceptional stature. Our selection criteria
                    prioritize qualifications and experience, ensuring the
                    highest standards of education.
                  </p>
                </div>
              </div>
              <div className="hero-section-image internal advanced-program"></div>
            </div>
          </div>
        </main>
      </section>

      <ScrollTrigger onEnter={() => setCounton(true)} onExit={() => setCounton(false)}>
      <div   class="w-layout-blockcontainer container w-container">
        <h1 class="h1 max-width-432-mobile-320 margin-bottom-56">
          Our Students
        </h1>
        <div class="w-layout-grid blocks-grid-4-tablet-2-mobile-1 text-center">
          <div
            class="block padding-24-32 bg-grey-3 w-inline-block"
          >
            <div class="wrapper justify-content-around">
              <div class="wrapper max-width-420 margin-bottom-32">
                <h3 class="h3 margin-bottom-16">
                DAE Mechanical Technology
                </h3>
              </div>
              <div class="tertiary-button">
                <h1>{counton && <CountUp start={0} end={50} duration={4} delay={0} />} +</h1>
              </div>
              <h4>Students</h4>
            </div>
          </div>
          <div
            class="block padding-24-32 bg-grey-3 w-inline-block"
          >
            <div class="wrapper justify-content-around">
              <div class="wrapper max-width-420 margin-bottom-32">
                <h3 class="h3 margin-bottom-16">
                  DAE Electrical Technology
                </h3>
              </div>
              <div class="tertiary-button">
                <h1>{counton && <CountUp start={0} end={50} duration={4} delay={0} />} +</h1>
              </div>
              <h4>Students</h4>
            </div>
          </div>

          <div
            class="block padding-24-32 bg-grey-3 w-inline-block"
          >
            <div class="wrapper justify-content-around">
              <div class="wrapper max-width-420 margin-bottom-32">
                <h3 class="h3 margin-bottom-16 px-lg-2">
                  DAE  Civil Technology
                </h3>
              </div>
              <div class="tertiary-button">
                <h1>{counton && <CountUp start={0} end={50} duration={4} delay={0} />} +</h1>
              </div>
              <h4>Students</h4>
            </div>
          </div>

          <div
            class="block padding-24-32 bg-grey-3 w-inline-block"
          >
            <div class="wrapper justify-content-around">
              <div class="wrapper max-width-420 margin-bottom-32">
                <h4 class="h4 margin-bottom-16">
                  DAE Computer Information Technology
                </h4>
              </div>
              <div class="tertiary-button">
                <h1>{counton && <CountUp start={0} end={50} duration={4} delay={0} />} +</h1>
              </div>
              <h4>Students</h4>
            </div>
          </div>

        </div>
      </div>
      </ScrollTrigger>
      <div class="container mt-5">
        <h1 class="mb-4">Work Force</h1>
        <p>
          SIT has faculty and teaching staff of good stature. Main focus for
          selection of faculty and staff lies in qualification and experience.
          At the moment profile of Faculty and teaching staff is as follows:
        </p>
        <table class="table table-striped my-4">
          <thead>
            <tr>
              <th>Category</th>
              <th>No</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Engineers</td>
              <td>05</td>
            </tr>
            <tr>
              <td>Bachelors in Technology</td>
              <td>02</td>
            </tr>
            <tr>
              <td>MS/MPhil</td>
              <td>01</td>
            </tr>
            <tr>
              <td>DAE</td>
              <td>05</td>
            </tr>
            <tr>
              <td>Certificate</td>
              <td>01</td>
            </tr>
            <tr>
              <td>Tradesman</td>
              <td>02</td>
            </tr>
          </tbody>
        </table>
        <p>
          It can be seen that the teacher to student ratio is very good. Even
          for two shifts the ratio to students is at 1:2. More teachers are
          likely to be inducted in due course.
        </p>
      </div>

      <JoinUs />
      <Footer />
    </>
  );
}

export default Facts;
