import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import JoinUs from "../../components/JoinUs";
import FloatingButton from "../../components/Floatingbutton";

function DaeMechanical() {
  return (
    <>
      <Navbar />
      <FloatingButton />
      <section class="section">
        <main class="hero-content">
          <div class="w-layout-blockcontainer container hero internal w-container">
            <div class="hero-section-content">
              <div class="hero-section-content-block internal bg-light-red">
                <div class="wrapper max-width-640-mobile-480">
                  <h1 class=" margin-bottom-28">
                    Elevate Your Advanced Education
                  </h1>
                  <p class="regular-xl max-width-420">
                    Challenge yourself with rigorous coursework, expand your
                    knowledge, and prepare for success in college and beyond.
                  </p>
                </div>
              </div>
              <div class="hero-section-image internal advanced-program"></div>
            </div>
          </div>
        </main>
      </section>

      {/* <div class="container mt-4">
        <table class="table table-striped table-bordered text-center">
            <tbody>
            <tr className="table-active">
                    <th>Programs</th>
                    <th>Admission fee one time</th>
                    <th>Tuition fee per month</th>
                    <th>Security fee (one time Refundable)</th>
                    <th>Student Card (one time)</th>
                    <th>Sports fund annually</th>
                    <th>Exam Charges Annually</th>
                </tr>
                <tr>
                    <td>DAE Civil</td>
                    <td>10,000</td>
                    <td>6,000</td>
                    <td>5,000</td>
                    <td>200</td>
                    <td>500</td>
                    <td>2,000</td>
                </tr>
                <tr>
                    <td>DAE Electrical</td>
                    <td>10,000</td>
                    <td>6,000</td>
                    <td>5,000</td>
                    <td>200</td>
                    <td>500</td>
                    <td>2,000</td>
                </tr>
                <tr>
                    <td>DAE Mechanical</td>
                    <td>10,000</td>
                    <td>6,000</td>
                    <td>5,000</td>
                    <td>200</td>
                    <td>500</td>
                    <td>2,000</td>
                </tr>
                <tr>
                    <td>DAE CIT</td>
                    <td>10,000</td>
                    <td>6,000</td>
                    <td>5,000</td>
                    <td>200</td>
                    <td>500</td>
                    <td>2,000</td>
                </tr>
            </tbody>
        </table>
    </div> */}
      <div class="container mt-5 lh-lg">
      <header className="text-center fw-bold mb-3">
        <h2>DAE Mechanical Technology</h2>
        </header>
        <p>
          Mechanical technology is the application of engineering principles and
          technological developments for the creation of useful products and
          production machinery. Students in this program gain hands-on
          experience and theoretical knowledge to excel in various mechanical
          engineering fields.
        </p>

        <section class="mt-5">
          <h3>Laboratories</h3>
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Laboratory</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Basic Machine Shop</td>
                <td>
                  Equipped with essential tools and machinery for basic
                  machining operations.
                </td>
              </tr>
              <tr>
                <td>Welding Shop</td>
                <td>
                  Provides facilities for learning various welding processes
                  used in metal fabrication.
                </td>
              </tr>
              <tr>
                <td>Foundry Shop</td>
                <td>Offers practical training in metal casting processes.</td>
              </tr>
              <tr>
                <td>Advanced Machine Shop</td>
                <td>
                  Features advanced machinery and equipment for complex
                  machining operations.
                </td>
              </tr>
              <tr>
                <td>AutoCAD/CAM Lab</td>
                <td>
                  Equipped with computer-aided design and manufacturing
                  software.
                </td>
              </tr>
              <tr>
                <td>Engine Shop</td>
                <td>Provides hands-on experience with engines.</td>
              </tr>
              <tr>
                <td>Computer Lab</td>
                <td>
                  Equipped with software for simulations, analysis, and design.
                </td>
              </tr>
              <tr>
                <td>Physics Lab</td>
                <td>Provides a foundation in physics principles.</td>
              </tr>
              <tr>
                <td>Chemistry Lab</td>
                <td>Focuses on the chemical properties of materials.</td>
              </tr>
              <tr>
                <td>Wood Work Lab</td>
                <td>Offers practical training in woodworking techniques.</td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>


      <section className="container d-flex flex-md-nowrap flex-wrap justify-content-around mt-5 mb-2">
          <div className="col-md-4 col-12">
            <img className="img-fluid" src={require("../../Photos/Mechanical_lab1.jpg")}></img>
          </div>
          <div className="col-md-4 col-12 mx-md-2 my-md-0 my-2">
            <img className="img-fluid" src={require("../../Photos/Mechanical_lab2.jpg")}></img>
          </div>
          <div className="col-md-4 col-12">
            <img className="img-fluid" src={require("../../Photos/Mechanical_lab3.jpg")}></img>
          </div>
        </section>
        <section className="d-flex flex-md-nowrap flex-wrap justify-content-around mb-5 ">
          <div className="col-md-4 col-12">
            <img className="img-fluid" src={require("../../Photos/Mechanical_lab4.jpg")}></img>
          </div>
          <div className="col-md-4 col-12 mx-md-2 my-md-0 my-2">
            <img className="img-fluid" src={require("../../Photos/Mechanical_lab5.jpg")}></img>
          </div>
          {/* <div className="col-md-4 col-12">
            <img className="img-fluid" src={require("../../Photos/Electrical_lab_5.JPG")}></img>
          </div> */}
        </section>
      <section class="container mt-5">
        <h2>Scheme of Study for Mechanical Technology</h2>
        <div class=" mt-5" style={{ overflowX: "auto" }}>
          <h2 class="">First Year</h2>
          <table class="table table-striped table-bordered table-bordered">
            <thead>
              <tr>
                <th>S NO</th>
                <th>1st Year</th>
                <th>Code</th>
                <th>Subject</th>
                <th>T</th>
                <th>P</th>
                <th>C</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Gen</td>
                <td>111</td>
                <td>Islamyat & Pak Studies</td>
                <td>1</td>
                <td>0</td>
                <td>1</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Eng</td>
                <td>112</td>
                <td>English</td>
                <td>2</td>
                <td>0</td>
                <td>2</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Math</td>
                <td>123</td>
                <td>Applied Mathematics-1</td>
                <td>3</td>
                <td>0</td>
                <td>3</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Phy</td>
                <td>122</td>
                <td>Applied Physics</td>
                <td>1</td>
                <td>3</td>
                <td>2</td>
              </tr>
              <tr>
                <td>5</td>
                <td>Chem</td>
                <td>112</td>
                <td>Applied Chemistry</td>
                <td>1</td>
                <td>3</td>
                <td>2</td>
              </tr>
              <tr>
                <td>6</td>
                <td>Mech</td>
                <td>127</td>
                <td>Workshop Practice</td>
                <td>0</td>
                <td>15</td>
                <td>7</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>(a) General Metal Work</td>
                <td>0</td>
                <td>3</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>(b) Wood Works</td>
                <td>0</td>
                <td>3</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>(c) Welding & Forging</td>
                <td>0</td>
                <td>3</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>(d) Foundry</td>
                <td>0</td>
                <td>3</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>(e) Basic Machine Shop -I Theory</td>
                <td>2</td>
                <td>0</td>
                <td>2</td>
              </tr>
              <tr>
                <td>7</td>
                <td>Comp</td>
                <td>152</td>
                <td>Computer Application</td>
                <td>1</td>
                <td>3</td>
                <td>2</td>
              </tr>
              <tr>
                <td>8</td>
                <td>Mech</td>
                <td>151</td>
                <td>Occupation Health Safety & Environment</td>
                <td>1</td>
                <td>0</td>
                <td>1</td>
              </tr>
              <tr>
                <td>9</td>
                <td>Mech</td>
                <td>173</td>
                <td>Engineering Drawing & Graphics</td>
                <td>1</td>
                <td>6</td>
                <td>3</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <strong>Total</strong>
                </td>
                <td>13</td>
                <td>30</td>
                <td>23</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class=" mt-5" style={{ overflowX: "auto" }}>
          <h2 class="">Second Year</h2>
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th>S NO</th>
                <th>2nd YEAR</th>
                <th>Code</th>
                <th>Subject</th>
                <th>T</th>
                <th>P</th>
                <th>C</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Gen</td>
                <td>211</td>
                <td>Islamyat & Pak Studies</td>
                <td>1</td>
                <td>0</td>
                <td>1</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Phy</td>
                <td>212</td>
                <td>Applied Mechanics</td>
                <td>1</td>
                <td>3</td>
                <td>2</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Math</td>
                <td>212</td>
                <td>Applied Mathematics-II</td>
                <td>2</td>
                <td>0</td>
                <td>2</td>
              </tr>
              <tr>
                <td>4</td>
                <td>MGM</td>
                <td>201</td>
                <td>Communication Skills & Report Writing</td>
                <td>1</td>
                <td>0</td>
                <td>1</td>
              </tr>
              <tr>
                <td>5</td>
                <td>MGM</td>
                <td>221</td>
                <td>Business Management & Industrial Economics</td>
                <td>1</td>
                <td>0</td>
                <td>1</td>
              </tr>
              <tr>
                <td>6</td>
                <td>Elect</td>
                <td>212</td>
                <td>Applied Electricity & Electronics</td>
                <td>1</td>
                <td>3</td>
                <td>2</td>
              </tr>
              <tr>
                <td>7</td>
                <td>Mech</td>
                <td>233</td>
                <td>Computer Aided Design (CAD)</td>
                <td>1</td>
                <td>6</td>
                <td>3</td>
              </tr>
              <tr>
                <td>8</td>
                <td>Mech</td>
                <td>246</td>
                <td>Workshop Practice -II</td>
                <td>0</td>
                <td>12</td>
                <td>6</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>(a) Basic Machine Shop -II</td>
                <td>0</td>
                <td>6</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>(b) Foundry and Pattern Making</td>
                <td>0</td>
                <td>3</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>(c) Adv. Welding Theory</td>
                <td>2</td>
                <td>0</td>
                <td>2</td>
              </tr>
              <tr>
                <td>9</td>
                <td>Mech</td>
                <td>262</td>
                <td>Metallurgy</td>
                <td>2</td>
                <td>0</td>
                <td>2</td>
              </tr>
              <tr>
                <td>10</td>
                <td>Mech</td>
                <td>272</td>
                <td>Metrology</td>
                <td>1</td>
                <td>3</td>
                <td>2</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <strong>Total</strong>
                </td>
                <td>13</td>
                <td>27</td>
                <td>22</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class=" mt-5" style={{ overflowX: "auto" }}>
          <h2 class="">Third Year</h2>
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th>S NO</th>
                <th>3rd Year</th>
                <th>Code</th>
                <th>Subject</th>
                <th>T</th>
                <th>P</th>
                <th>C</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Gen</td>
                <td>311</td>
                <td>Islamyat & Pak Studies</td>
                <td>1</td>
                <td>0</td>
                <td>1</td>
              </tr>
              <tr>
                <td>2</td>
                <td>IMH</td>
                <td>301</td>
                <td>Industrial Management & Human Relations</td>
                <td>1</td>
                <td>0</td>
                <td>1</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Mech</td>
                <td>302</td>
                <td>Fluid Mechanics and Hydraulic Machines</td>
                <td>1</td>
                <td>3</td>
                <td>2</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Mech</td>
                <td>313</td>
                <td>Applied Thermodynamics</td>
                <td>2</td>
                <td>3</td>
                <td>3</td>
              </tr>
              <tr>
                <td>5</td>
                <td>Mech</td>
                <td>321</td>
                <td>Industrial Planning and Production Methods</td>
                <td>1</td>
                <td>0</td>
                <td>1</td>
              </tr>
              <tr>
                <td>6</td>
                <td>Mech</td>
                <td>333</td>
                <td>Machine Design & Analysis</td>
                <td>2</td>
                <td>3</td>
                <td>3</td>
              </tr>
              <tr>
                <td>7</td>
                <td>Mech</td>
                <td>363</td>
                <td>Tool & Mold Design</td>
                <td>2</td>
                <td>3</td>
                <td>3</td>
              </tr>
              <tr>
                <td>8</td>
                <td>Mech</td>
                <td>332</td>
                <td>Material testing & Heat Treatment</td>
                <td>1</td>
                <td>3</td>
                <td>2</td>
              </tr>
              <tr>
                <td>9</td>
                <td>Mech</td>
                <td>354</td>
                <td>Workshop Practice -III</td>
                <td>2</td>
                <td>6</td>
                <td>4</td>
              </tr>
              <tr>
                <td>10</td>
                <td>Mech</td>
                <td>352</td>
                <td>CAD/CAM</td>
                <td>1</td>
                <td>3</td>
                <td>2</td>
              </tr>
              <tr>
                <td>11</td>
                <td>Mech</td>
                <td>372</td>
                <td>CNC Machines</td>
                <td>1</td>
                <td>3</td>
                <td>2</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <strong>Total</strong>
                </td>
                <td>15</td>
                <td>27</td>
                <td>24</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <JoinUs />
      <Footer />
    </>
  );
}

export default DaeMechanical;
