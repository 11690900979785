import React from "react";
import Navbar from "../../components/Navbar";
import FloatingButton from "../../components/Floatingbutton";
import JoinUs from "../../components/JoinUs";
import Footer from "../../components/Footer";

function DaeCIT() {
  return (
    <>
      <Navbar />
      <FloatingButton />

      <section class="section">
        <main class="hero-content">
          <div class="w-layout-blockcontainer container hero internal w-container">
            <div class="hero-section-content">
              <div class="hero-section-content-block internal bg-light-red">
                <div class="wrapper max-width-640-mobile-480">
                  <h1 class=" margin-bottom-28">
                    Elevate Your Advanced Education
                  </h1>
                  <p class="regular-xl max-width-420">
                    Challenge yourself with rigorous coursework, expand your
                    knowledge, and prepare for success in college and beyond.
                  </p>
                </div>
              </div>
              <div class="hero-section-image internal advanced-program"></div>
            </div>
          </div>
        </main>
      </section>
      <div class="container mt-5 lh-lg">
        <header class="">
          <h2 className="text-center">Computer Information Technology (CIT)</h2>
        </header>
        <section class="mt-5">
          <p>
            Computer Information Technology (CIT) involves the utilization and
            exploration of computers, networks, programming languages, and
            databases to address real-world challenges within organizations.
            Major subjects equip students with skills in application
            programming, networking, systems administration, and internet
            development.
          </p>
        </section>
        <section class="mt-5">
          <h3>Laboratories</h3>
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Lab</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>Computer Lab</strong>
                </td>
                <td>
                  Equipped with state-of-the-art computers and software for
                  hands-on learning and practical applications.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Hardware Lab</strong>
                </td>
                <td>
                  Provides hands-on experience with computer hardware
                  components, assembly, maintenance, and troubleshooting.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Physics Lab</strong>
                </td>
                <td>
                  Supports the understanding of fundamental principles related
                  to computing and technology.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Chemistry Lab</strong>
                </td>
                <td>
                  Offers insights into the chemical aspects of materials used in
                  computing technology, enhancing students' understanding of
                  hardware and software interactions.
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <section className="d-flex flex-md-nowrap flex-wrap justify-content-around my-5">
          <div className="col-12">
            <img className="img-fluid" src={require("../../Photos/Cit-pic1.jpeg")}></img>
          </div>
        </section>
 
        <section class=" mt-5">
          <h2>Scheme of Study for CIT</h2>
          <div class=" mt-5 " style={{ overflowX: "auto" }}>
            <h2 class="">FIRST YEAR</h2>
            <table class="table table-striped table-bordered table-bordered">
              <thead>
                <tr>
                  <th>SNO</th>
                  <th>1st Year</th>
                  <th>Code</th>
                  <th>Subject</th>
                  <th>T</th>
                  <th>P</th>
                  <th>C</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Gen</td>
                  <td>111</td>
                  <td>Islamyat & Pak Studies</td>
                  <td>1</td>
                  <td>0</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Eng</td>
                  <td>112</td>
                  <td>English</td>
                  <td>2</td>
                  <td>0</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Math</td>
                  <td>123</td>
                  <td>Applied Mathematics-1</td>
                  <td>3</td>
                  <td>0</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Phy</td>
                  <td>132</td>
                  <td>Applied Physics</td>
                  <td>1</td>
                  <td>3</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Chem</td>
                  <td>132</td>
                  <td>Applied Chemistry</td>
                  <td>1</td>
                  <td>3</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>OHSE</td>
                  <td>111</td>
                  <td>Occupational Health, Safety & Environment</td>
                  <td>1</td>
                  <td>0</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>CIT</td>
                  <td>112</td>
                  <td>Computer Application Software</td>
                  <td>0</td>
                  <td>6</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>CIT</td>
                  <td>113</td>
                  <td>Introduction to Computer Programming</td>
                  <td>2</td>
                  <td>3</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>CIT</td>
                  <td>121</td>
                  <td>General Engineering Workshop</td>
                  <td>0</td>
                  <td>3</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>CIT</td>
                  <td>134</td>
                  <td>Electronics</td>
                  <td>3</td>
                  <td>3</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <strong>Total</strong>
                  </td>
                  <td>
                    <strong>14</strong>
                  </td>
                  <td>
                    <strong>21</strong>
                  </td>
                  <td>
                    <strong>21</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="mt-5" style={{ overflowX: "auto" }}>
            <h2 class="">SECOND YEAR</h2>
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SNO</th>
                  <th>1st Year</th>
                  <th>Code</th>
                  <th>Subject</th>
                  <th>T</th>
                  <th>P</th>
                  <th>C</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Gen</td>
                  <td>211</td>
                  <td>Islamyat & Pak Studies</td>
                  <td>1</td>
                  <td>0</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Math</td>
                  <td>233</td>
                  <td>Applied Mathematics-II</td>
                  <td>3</td>
                  <td>0</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>CIT</td>
                  <td>211</td>
                  <td>Business Communication</td>
                  <td>1</td>
                  <td>0</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>CIT</td>
                  <td>212</td>
                  <td>Object Oriented Programming with Java</td>
                  <td>1</td>
                  <td>3</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>CIT</td>
                  <td>223</td>
                  <td>Computer Networks</td>
                  <td>2</td>
                  <td>3</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>CIT</td>
                  <td>235</td>
                  <td>Micro-Processor Architecture</td>
                  <td>3</td>
                  <td>6</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>CIT</td>
                  <td>244</td>
                  <td>Electronic-II</td>
                  <td>3</td>
                  <td>3</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>CIT</td>
                  <td>263</td>
                  <td>Relational Data-Base Management System</td>
                  <td>2</td>
                  <td>3</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <strong>Total</strong>
                  </td>
                  <td>
                    <strong>16</strong>
                  </td>
                  <td>
                    <strong>18</strong>
                  </td>
                  <td>
                    <strong>22</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class=" mt-5" style={{ overflowX: "auto" }}>
            <h2 class="">THIRD YEAR</h2>
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SNO</th>
                  <th>1st Year</th>
                  <th>Code</th>
                  <th>Subject</th>
                  <th>T</th>
                  <th>P</th>
                  <th>C</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Gen</td>
                  <td>311</td>
                  <td>Islamyat & Pak Studies</td>
                  <td>1</td>
                  <td>0</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Eng</td>
                  <td>311</td>
                  <td>Technical Report Writing</td>
                  <td>1</td>
                  <td>0</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Mgt</td>
                  <td>331</td>
                  <td>Management</td>
                  <td>1</td>
                  <td>0</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>CIT</td>
                  <td>303</td>
                  <td>Web Development with JAVA</td>
                  <td>1</td>
                  <td>6</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>CIT</td>
                  <td>324</td>
                  <td>Network Administration</td>
                  <td>2</td>
                  <td>6</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>CIT</td>
                  <td>333</td>
                  <td>Operating System</td>
                  <td>2</td>
                  <td>3</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>CIT</td>
                  <td>344</td>
                  <td>Graphic Designing</td>
                  <td>2</td>
                  <td>6</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>CIT</td>
                  <td>352</td>
                  <td>PC System & Peripheral Repair</td>
                  <td>1</td>
                  <td>3</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>CIT</td>
                  <td>362</td>
                  <td>Project</td>
                  <td>0</td>
                  <td>6</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <strong>Total</strong>
                  </td>
                  <td>
                    <strong>11</strong>
                  </td>
                  <td>
                    <strong>30</strong>
                  </td>
                  <td>
                    <strong>21</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </div>

      <JoinUs />
      <Footer />
    </>
  );
}

export default DaeCIT;
