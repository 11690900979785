import React from 'react'
import JoinUs from '../../components/JoinUs';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import FloatingButton from '../../components/Floatingbutton';

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../CSS/Testimonial.css";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
function PartnerSchoolFeedback() {
    const testimonials = [
        {
          description:
            "As an electrical engineer and a teacher I believe that  a skilled person is  the requirement of modern world. DAE is a program which gives education along with practical skill. I visited SIT  at chakri road rawalpindi few days ago. Its a good initiative. I am glad because its first institute of technology in this area with well equiped labs and better environment. Parent must visit and get advantage for their successors for a wide scope in future.",
          name: "Engr. Sher Muhammad Awan",
          title: "Light house school system (chakri road campus)",
          image: require("../../Photos/engr.sherAwan.jpeg"),
        },
      
        {
          description:
            "As an electrical engineer and a teacher I believe that  a skilled person is  the requirement of modern world. DAE is a program which gives education along with practical skill. I visited SIT  at chakri road rawalpindi few days ago. Its a good initiative. I am glad because its first institute of technology in this area with well equiped labs and better environment. Parent must visit and get advantage for their successors for a wide scope in future.",
          name: "Engr. Sher Muhammad Awan",
          title: "Light house school system (chakri road campus)",
          image: require("../../Photos/engr.sherAwan.jpeg"),
        },
      ];
      
  return (
    <>
       <Navbar/>
    <FloatingButton/>
      <section class="section mb-5">
        <main class="hero-content">
          <div class="w-layout-blockcontainer container hero internal w-container">
            <div class="hero-section-content">
              <div class="hero-section-content-block internal bg-yellow-green">
                <div class="wrapper max-width-640-mobile-480">
                  <h1 class="h1 margin-bottom-28">
                  Empowering Minds, Enriching Futures: Partnering for Educational Excellence
                  </h1>
                  <p class="regular-xl max-width-420">
                  At SKANS Institute Of Technology, we believe in the transformative power of collaboration. By partnering with schools, we aim to expand educational horizons and empower students with critical thinking skills and ethical values. Together, we're creating a vibrant community where innovation meets tradition, preparing the leaders of tomorrow to navigate and shape a complex global landscape.
                  </p>
                </div>
                {/* <a href="/about" class="button primary w-button">
                  About School
                </a> */}
              </div>
              <div class="hero-section-image internal departments"></div>
            </div>
          </div>
        </main>
      </section>

      <div className="container">
        <h2 className="mb-5">Partner Schools Feedback</h2>
        <div className="row">
          <div className="col-md-offset-2 col-md-12 container">
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              slidesPerView={1}
              className="mySwiper"
            >
              {testimonials.map((testimonial, index) => (
                <SwiperSlide key={index}>
                  <div className="testimonial container">
                    <div className="testimonial-content">
                      <p className="description">{testimonial.description}</p>
                    </div>
                    <div className="testimonial-profile">
                      <div className="pic">
                        <img src={testimonial.image} alt={testimonial.name} />
                      </div>
                      <h3 className="title">
                        {testimonial.name}
                        <span className="post">{testimonial.title}</span>
                      </h3>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>

      <JoinUs/>
      <Footer/>
    </>
  )
}

export default PartnerSchoolFeedback